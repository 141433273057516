//@ts-nocheck
import React from 'react';
import { useParams } from 'react-router-dom';

import SystemSettingsForm from 'components/forms/SystemSettingsForm';
import MainContent from 'components/MainContent';
import SubAccountPage from './SubAccountPage';
import ContentWrapper from 'components/ContentWrapper';

const SystemSettingsScreen = () => {
  const { submenuPath } = useParams();

  if (submenuPath === 'sub_accounts') {
    return (
      <ContentWrapper style={{ padding: '20px 40px', position: 'relative' }} maxWidthContent fullHeight>
        <SubAccountPage />
      </ContentWrapper>
    );
  }

  return (
    <MainContent type="form">
      <SystemSettingsForm />
    </MainContent>
  );
};

export default SystemSettingsScreen;
