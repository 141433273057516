//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import { DownloadIcon, Table, Button, DatePickerProps, DatePicker } from '@gamesb42/ui-kit';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ContentWrapper from 'components/ContentWrapper';
import Title from 'components/common/Title';
import DateInput, { DatePikerEnum } from 'components/inputs/DateInput';
import CompanySelect from 'components/CompanySelect';
import { getToken } from 'helpers/token';
import { getFileFA } from 'components/forms/CompanyForm/reportsData';
import { formatAmount } from 'helpers/formatters';

import s from './styles.module.scss';

const SellerAccountBalance = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<DatePickerProps['value']>(undefined);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState('');
  const [total, setTotal] = useState({ eur: '', usd: '' });

  const columns = [
    {
      title: 'Client',
      dataIndex: 'company_name',
      width: '187px',
      align: 'left' as 'left',
    },
    {
      title: 'IBAN',
      dataIndex: 'iban_eur',
      width: '187px',
      align: 'right' as 'right',
      render: (_, { iban_eur }) => {
        return iban_eur ? iban_eur : '-';
      },
    },
    {
      title: 'Balance EUR',
      dataIndex: 'balance_eur',
      width: '187px',
      align: 'right' as 'right',
      render: (_, { balance_eur }) => {
        return balance_eur ? `${formatAmount(balance_eur)}` : 'N/A';
      },
    },
    {
      title: 'IBAN',
      dataIndex: 'iban_usd',
      width: '187px',
      align: 'right' as 'right',
      render: (_, { iban_usd }) => {
        return iban_usd ? iban_usd : '-';
      },
    },
    {
      title: 'Balance USD',
      dataIndex: 'balance_usd',
      width: '187px',
      align: 'right' as 'right',
      render: (_, { balance_usd }) => {
        return balance_usd ? `${formatAmount(balance_usd)}` : 'N/A';
      },
    },
  ];

  function prepareData(data) {
    return data.items;
  }

  function getData() {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    let baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance`;

    if (value && companyId?.length > 0) {
      baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance?report_date=${value.format(
        'YYYY-MM-DD',
      )}&company_id=${companyId}`;
    } else if (value) {
      baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance?report_date=${value.format(
        'YYYY-MM-DD',
      )}`;
    } else if (companyId?.length > 0) {
      baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance?company_id=${companyId}`;
    }

    fetch(baseUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(prepareData(result));
        setTotal({ eur: result.total_eur, usd: result.total_usd });
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleDownload() {
    let baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance/csv`;

    if (value && companyId?.length > 0) {
      baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance/csv?report_date=${value.format(
        'YYYY-MM-DD',
      )}&company_id=${companyId}`;
    } else if (value) {
      baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance/csv?report_date=${value.format(
        'YYYY-MM-DD',
      )}`;
    } else if (companyId?.length > 0) {
      baseUrl = `${process.env.REACT_APP_BASE_URL}/reports/seller_account_balance/csv?company_id=${companyId}`;
    }

    getFileFA(baseUrl, undefined, 'text/csv');
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [value, companyId]);

  return (
    <ContentWrapper style={{ padding: '20px 40px', position: 'relative' }} maxWidthContent fullHeight>
      <div className={s.contentWrapper}>
        <Title className={s.titleWrapper} text={t('menu.sellerAccountBalance')} />
        <div className={s.actionBlock}>
          <div style={{ display: 'flex', gap: '12px' }}>
            <DatePicker
              size="middle"
              style={{ width: '200px' }}
              format="DD.MM.YYYY"
              label="Select date"
              value={value}
              onChange={setValue}
            />
            <div style={{ width: 470 }}>
              <CompanySelect
                size="middle"
                label="Client"
                value={companyId}
                onChange={(id) => {
                  setCompanyId(id);
                }}
                onClear={() => {}}
                allowClear
              />
            </div>
          </div>
          <Button
            style={{ width: '40px', height: '40px' }}
            icon={<DownloadIcon size={16} />}
            onClick={handleDownload}
          />
        </div>
        <div style={{ maxWidth: '950px' }}>
          <Table
            className={s.dataTable}
            dataSource={data}
            columns={columns}
            loading={isLoading}
            initialHeight="500px"
            footer={() => (
              <div style={{ display: 'flex' }}>
                <div style={{ width: '187px', paddingLeft: '16px' }}>
                  <b>Total</b>
                </div>
                <div style={{ width: '187px' }}></div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '187px', paddingRight: '16px' }}>
                  <b>{total.eur}</b>
                </div>
                <div style={{ width: '187px' }}></div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '187px', paddingRight: '16px' }}>
                  <b>{total.usd}</b>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default SellerAccountBalance;
