import { ValueFormatT } from 'components/common/GeneralView/Item';

export enum GeneralEnum {
  CAPTION = 'caption',
  COMPANY = 'company',
  COMPANY_ID = 'company_id',
  AO = 'ao',
  AO_ID = 'ao_id',
  FILE = 'file',
  LINK = 'link',
  PURCHASE_DATE = 'purchase_date',
  REQUEST = 'request',
  STATUS = 'status',
  REQUEST_DATE = 'request_date',
  PURCHASED_RECEIVABLES = 'purchased_receivables',
  PURCHASE_PRICE = 'purchase_price',
  TYPE = 'ao_type',
  PURCHASE_AMOUNT = 'purchase_amount',
  OUTSTANDING = 'outstanding',
  REPAYMENT_DATE = 'repayment_date',
  TRANSACTION = 'transaction_id',
  TRANSACTION_TS = 'transaction_ts_id',
  CURRENCY = 'currency',
  EXPECTED_DATE = 'expected_date',
  PR_DATE = 'pr_date',
}

export enum PlatformEnum {
  CAPTION = 'caption',
  platform = 'platform',
  platform_logo = 'platform_logo',
  ar = 'ar',
  purchased = 'purchased',
  purchase_price = 'purchase_price',
  due_date = 'due_date',
  outstanding = 'outstanding',
  claim_id = 'claim_id',
  period = 'period',
}

export interface IPlatforms {
  platform: string;
  platform_logo: string;
  ar?: string;
  purchased?: string;
  purchase_price?: string;
  due_date?: string;
}

export interface IPlatformTableConfig {
  field: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  styles?: { minWidth?: number; maxWidth?: number; width?: number; paddingRight?: number };
  valueFormatType?: ValueFormatT;
}

export const PlatformTableConfig: IPlatformTableConfig[] = [
  {
    field: PlatformEnum.platform,
    align: 'left',
  },
  {
    field: PlatformEnum.ar,
    align: 'right',
    valueFormatType: 'money',
  },
  {
    field: PlatformEnum.period,
    align: 'right',
  },
  {
    field: PlatformEnum.purchased,
    align: 'right',
    valueFormatType: 'money',
  },
  {
    field: PlatformEnum.purchase_price,
    align: 'right',
    valueFormatType: 'money',
  },
  {
    field: PlatformEnum.due_date,
    align: 'right',
  },
  {
    field: PlatformEnum.outstanding,
    align: 'right',
  },
];
