import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Avatar, MinusIcon, PageHeader, Collapse, Table, Skeleton } from '@gamesb42/ui-kit';
import { useHistory, useParams } from 'react-router-dom';
import ActionButton from './ActionButton';
import { formatAmount } from 'helpers/formatters';
import Status from 'components/common/Status';

import s from './workingCapital.module.scss';

export default function CardWorkingCapital() {
  const history = useHistory();
  //@ts-ignore
  const { workingCapitalId } = useParams();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: 'Repayment date',
      dataIndex: 'repayment_date',
      render: (_: any, record: any) => {
        return moment(record.repayment_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
    },
    {
      title: 'Amount',
      align: 'right' as 'right',
      dataIndex: 'repayment_amount',
      render: (_: any, record: any) => {
        return `${data.currency === 'EUR' ? '€' : '$'}${formatAmount(record.repayment_amount)}`;
      },
    },
  ];

  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);

    fetch(`${process.env.REACT_APP_BASE_URL}/tranche/${workingCapitalId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  console.log({ data });

  return (
    <div className={s.contentWrapper}>
      <div className={s.cardForm}>
        <PageHeader
          title={'Tranche Details'}
          back
          onBack={() => history.goBack()}
          //@ts-ignore
          leftContent={<Status status={data?.status} />}
          rightContent={<ActionButton name={'test'} updateCompany={() => {}} />}
        />
        <div className={s.row}>
          <div className={s.item}>
            <div className={s.itemRow}>
              <div className={s.left}>Amount:</div>
              <div className={s.rigth}>
                {isLoading && <Skeleton type="status" />}
                {!isLoading && `${formatAmount(data?.amount)}${data?.currency === 'EUR' ? '€' : '$'}`}
              </div>
            </div>
            <div className={s.itemRow}>
              <div className={s.left}>Interest:</div>
              <div className={s.rigth}>
                {isLoading && <Skeleton type="status" />}
                {!isLoading && `${Number.parseFloat(data?.interest).toFixed(1)}%`}
              </div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.itemRow}>
              <div className={s.left}>Issue date:</div>
              <div className={s.rigth}>
                {isLoading && <Skeleton type="status" />}
                {!isLoading && moment(data?.receipt_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
              </div>
            </div>
            <div className={s.itemRow}>
              <div className={s.left}>Repayment date:</div>
              <div className={s.rigth}>
                {isLoading && <Skeleton type="status" />}
                {!isLoading && moment(data?.return_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
              </div>
            </div>
          </div>
        </div>

        <div className={s.rowNext}>
          <div className={s.left}>Counterparty:</div>
          <div className={s.right}>
            {isLoading && <Skeleton type="status" />}
            {!isLoading && data?.counterparty?.length > 0 ? data?.counterparty : <MinusIcon size={16} />}
          </div>
        </div>
        <div className={s.rowNext}>
          <div className={s.left}>Loan description:</div>
          <div className={s.right}>
            {isLoading && <Skeleton type="status" />}
            {!isLoading && data?.loan_description?.length > 0 ? data?.loan_description : <MinusIcon size={16} />}
          </div>
        </div>
        <div className={s.rowNext}>
          <div className={s.left}>Comment:</div>
          <div className={s.right}>
            {isLoading && <Skeleton type="status" />}
            {!isLoading && data?.comment?.length > 0 ? data?.comment : <MinusIcon size={16} />}
          </div>
        </div>
        <div className={s.rowNext}>
          <div className={s.left}>Editor:</div>
          <div className={s.rightWithImg}>
            {isLoading && (
              <>
                <Skeleton type="avatar" />
                <Skeleton type="status" />
              </>
            )}
            {!isLoading && <Avatar className={s.avatarText} name={data?.editor?.name} />}
          </div>
        </div>
        {data?.repayments?.length > 0 && (
          <Collapse style={{ marginTop: '40px' }} collapsible="header" defaultActiveKey={['1']}>
            <Collapse.Panel header="Partial repayment" key="1">
              <Table className={s.table} columns={columns} dataSource={data?.repayments} />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
    </div>
  );
}
