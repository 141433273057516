//@ts-nocheck
import React, { useState } from 'react';
import { Modal, Select, Input, DoneRoundIcon, CloseBigIcon } from '@gamesb42/ui-kit';

import { getCompanies } from 'api/company';
import CompanySelect from 'components/CompanySelect';
import useAlert from 'hooks/api/useAlert';

export default function EditModal({ onClose, getData, activeRecord }) {
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState(activeRecord?.currency);
  const [iban, setIban] = useState(activeRecord.iban_number);
  const [company, setCompany] = useState(activeRecord?.company_id);
  const [status, setStatus] = useState(activeRecord?.status.replace(/^./, (str) => str.toUpperCase()));
  const { setAlert } = useAlert();

  const handleEdit = (result) => {
    const myHeaders = new Headers();
    myHeaders.append('content-type', 'application/json');

    const raw = JSON.stringify({
      currency: value2,
      bank_name: activeRecord.bank_name,
      iban_number: iban,
      status: status.toLowerCase(),
      company_id: company ? company : null,
    });

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
    };

    fetch(`${process.env.REACT_APP_SCA_URL}/v1/subaccounts/${activeRecord.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {})
      .then(() => getData())
      .catch(() => {})
      .finally(() => {
        onClose();
        setAlert({
          type: 'success',
          message: (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <DoneRoundIcon size="24" color="#26B634" />
              IBAN {iban} was changed successful
              <CloseBigIcon size="24" />
            </div>
          ),
        });
      });
  };

  return (
    <Modal
      open
      title="Edit IBAN"
      okButtonProps={{
        onMouseDown: () => handleEdit(),
      }}
      onCancel={onClose}
      okText="Save"
      cancelText="Cancel"
      width="838px"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <Select
          size="large"
          label={value1?.length > 0 ? 'Bank' : 'Select Bank'}
          value={activeRecord?.bank_name}
          allowClear
          disabled
        />
        <div style={{ display: 'flex', gap: '32px' }}>
          <div style={{ width: '122px' }}>
            <Select width="122px" size="large" label="Currency" value={value2} onChange={setValue2} allowClear>
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          </div>
          <div style={{ width: '100%' }}>
            <Input size="large" label="IBAN" value={iban} disabled />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <CompanySelect
            size="large"
            label="Company"
            value={company}
            onChange={(id) => {
              setCompany(id);
            }}
            allowClear
          />
        </div>
        <div style={{ width: '100%' }}>
          <Select size="large" label="Status" value={status} onChange={setStatus} allowClear>
            <Select.Option value="Active">Active</Select.Option>
            <Select.Option value="Closed">Closed</Select.Option>
          </Select>
        </div>
      </div>
    </Modal>
  );
}
