import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import LeftSvg from 'assets/img/leftAo.svg';
import Status, { StatusEnumTitle } from 'components/common/Status';

import { MAP_AO_TYPE_TO_COLOR, MAP_AO_TYPE_TO_STRING } from './data';
import classes from './styles.module.scss';

interface Props {
  text?: string;
  className?: string;
  isShowBackButton?: boolean;
  clickCallback?: () => void;
  styles?: React.CSSProperties;
  status?: StatusEnumTitle;
  aoType?: AoTypeEnum;
  setStatus?: any;
}

const Title: FC<Props> = ({ text, isShowBackButton, clickCallback, status, styles, aoType, className, setStatus }) => {
  const { t } = useTranslation();

  return (
    <div style={styles} className={classNames(classes.wrapper, className)}>
      {isShowBackButton && (
        <button style={{ display: 'grid' }} type="button" onClick={clickCallback}>
          <img className={classes.icon} src={LeftSvg} alt="icon" />
        </button>
      )}
      {t(text as string)}
      {aoType && (
        <span className={classes.status} style={MAP_AO_TYPE_TO_COLOR[aoType]}>
          {t(`${MAP_AO_TYPE_TO_STRING[aoType]}`)}
        </span>
      )}
      {status && <Status status={status} />}
    </div>
  );
};

export default Title;
