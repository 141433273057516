import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import { deleteActivationOrderDataType } from 'queries/activationOrder/deleteActivationOrder';
import { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import leftSvg from 'assets/img/arrowLeft.svg';
import Button from 'components/uiKit/Button';
import { Account } from 'types/ActivationOrderTypes';

import ClassicAO from './ClassicAO';
import ApplaudAO from './ApplaudAO';
import ActionsButton from './ActionsButton';
import FutureReceivablesAO from './FutureReceivablesAO';
import AvailableReceivablesOA from './AvailableReceivablesOA';
import styles from './styles.module.scss';

type PropsT = {
  type: AoTypeEnum;
  activationOrderData: activationOrderDataType;
  deleteActivationOrder: (data: deleteActivationOrderDataType) => void;
  title: string;
  accounts: Account[];
  updateAccounts: (id: string) => void;
  setStatus?: any;
};

const General: FC<PropsT> = ({
  activationOrderData,
  deleteActivationOrder,
  type,
  title,
  accounts,
  updateAccounts,
  setStatus,
}) => {
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <ActionsButton
        type={type}
        deleteActivationOrder={deleteActivationOrder}
        id={activationOrderData.id}
        title={title}
        companyId={activationOrderData?.companyId}
        setStatus={setStatus}
      />
      {AoTypeEnum.CLASSIC === type && (
        <ClassicAO activationOrderData={activationOrderData} accounts={accounts} updateAccounts={updateAccounts} />
      )}
      {AoTypeEnum.APPLAUD_FIX === type && <ApplaudAO activationOrderData={activationOrderData} accounts={accounts} />}
      {AoTypeEnum.FUTURE_RECEIVABLES === type && (
        <FutureReceivablesAO
          activationOrderData={activationOrderData}
          accounts={accounts}
          updateAccounts={updateAccounts}
        />
      )}
      {AoTypeEnum.AVAILABLE_RECEIVABLES === type && (
        <AvailableReceivablesOA activationOrderData={activationOrderData} accounts={accounts} />
      )}
      <Button
        className={styles.generalBottom}
        startAdornment={<img className="dropZoneGeneralImg" src={leftSvg} alt="left" />}
        onClick={() => history.push(urls.getActivationOrder())}
      >
        Back to AO list
      </Button>
    </div>
  );
};

export default General;
