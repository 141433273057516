import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'antd';
import {
  Input,
  FormLayout,
  PageHeader,
  Select,
  InputNumber,
  Upload,
  Button,
  notification,
  Description,
  UploadImage,
  TrashIcon,
  Status,
} from '@gamesb42/ui-kit';

import MainContent from 'components/MainContent';
import RevenueSourcesStatus from 'components/RevenueSourcesStatus';
import {
  createRevenueSource,
  editRevenueSource,
  getRevenueSourceById,
  deleteRevenueSource,
  syncPds,
} from 'api/revenueSources';
import {
  RevenueSource,
  RevenueSourceForm,
  RevenueSourceCalendarType,
  RevenueSourceConnectionEnum,
  RevenueSourceKey,
  RevenueSourceStatus,
} from 'types/RevenueSources';
import {
  REVENUE_SOURCES_CALENDAR_TYPE_OPTIONS,
  REVENUE_SOURCES_SYNC_TYPE_OPTIONS,
  REVENUE_SOURCES_STATUS_COLOR,
  REVENUE_SOURCES_STATUS_NAME,
} from 'constants/revenueSources';
import { convertFileToBase64 } from 'helpers/file';

import styles from './styles.module.scss';

const RevenueSources = ({ match, history: { goBack } }: RouteComponentProps<{ platformId?: string }>) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const revenueSourceId = match.params.platformId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFinish = async ({ code, ...value }: RevenueSource) => {
    setLoading(true);

    const api = revenueSourceId ? editRevenueSource : createRevenueSource;
    const logo = form.getFieldValue(RevenueSourceKey.LOGO);
    const newRevenueSource: RevenueSourceForm = { ...value, [RevenueSourceKey.LOGO]: logo };

    if (logo instanceof File) {
      newRevenueSource[RevenueSourceKey.LOGO] = (await convertFileToBase64(logo)) as string;
    }

    api(newRevenueSource, revenueSourceId as string)
      .then(() => {
        setLoading(false);
        syncPds();
        goBack();
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setLoading(false);
      });
  };

  const onDelete = () => {
    setLoading(true);

    deleteRevenueSource(revenueSourceId as string)
      .then(() => {
        setLoading(false);
        syncPds();
        goBack();
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (revenueSourceId) {
      getRevenueSourceById(revenueSourceId)
        .then((revenueSource) => {
          form.setFieldsValue(revenueSource);
        })
        // eslint-disable-next-line no-console
        .catch(() => {
          notification.error({ message: 'Error when loading data' });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (!revenueSourceId) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContent type="form" title={t('title.revenueSources')}>
      <PageHeader
        title={
          revenueSourceId
            ? `${t('global.edit')} ${form.getFieldValue(RevenueSourceKey.NAME)}`
            : `${t('global.create')} ${t('title.revenueSources')}`
        }
        leftContent={
          revenueSourceId ? (
            <Status
              color={REVENUE_SOURCES_STATUS_COLOR[form.getFieldValue(RevenueSourceKey.STATUS) as RevenueSourceStatus]}
              className={styles.statusTable}
            >
              {REVENUE_SOURCES_STATUS_NAME[form.getFieldValue(RevenueSourceKey.STATUS) as RevenueSourceStatus]}
            </Status>
          ) : undefined
        }
        back
      />
      <Form form={form} disabled={loading} onFinish={onFinish}>
        {revenueSourceId !== undefined && (
          <FormLayout>
            <Form.Item
              name={RevenueSourceKey.STATUS}
              rules={[
                {
                  required: true,
                  message: `Please select ${t('global.status')}!`,
                },
              ]}
            >
              <RevenueSourcesStatus size="large" />
            </Form.Item>
            <div />
          </FormLayout>
        )}
        <Form.Item
          name={RevenueSourceKey.NAME}
          rules={[
            {
              required: true,
              message: `Please input name ${t('revenueSources.revenueSource')}!`,
            },
          ]}
        >
          <Input label={t('global.name')} size="large" />
        </Form.Item>
        <FormLayout>
          <Form.Item
            name={RevenueSourceKey.ACTIVATION_CUTOFF_PERIOD}
            rules={
              [
                // {
                //   required: true,
                //   message: `Please input ${t('revenueSources.activationCutoffPeriod')}!`,
                // },
              ]
            }
          >
            <InputNumber label={t('revenueSources.activationCutoffPeriod')} size="large" min={0} max={31} />
          </Form.Item>
          <Form.Item
            name={RevenueSourceKey.ADVANCE_CUTOFF_PERIOD}
            rules={
              [
                // {
                //   required: true,
                //   message: `Please input ${t('revenueSources.advanceCutoffPeriod')}!`,
                // },
              ]
            }
          >
            <InputNumber label={t('revenueSources.advanceCutoffPeriod')} size="large" min={0} max={31} />
          </Form.Item>
        </FormLayout>
        <FormLayout>
          <Form.Item
            name={RevenueSourceKey.CALENDAR_TYPE}
            rules={
              [
                // {
                //   required: true,
                //   message: `Please select ${t('revenueSources.paymentCalendarType')}!`,
                // },
              ]
            }
          >
            <Select
              label={t('revenueSources.paymentCalendarType')}
              options={REVENUE_SOURCES_CALENDAR_TYPE_OPTIONS}
              size="large"
            />
          </Form.Item>
          <Form.Item dependencies={[RevenueSourceKey.CALENDAR_TYPE]} noStyle>
            {({ getFieldValue }) => {
              const calendarType = getFieldValue([RevenueSourceKey.CALENDAR_TYPE]);

              return calendarType === RevenueSourceCalendarType.DEFAULT ? (
                <Form.Item
                  name={RevenueSourceKey.NET_DAY}
                  rules={
                    [
                      // {
                      //   required: true,
                      //   message: `Please input ${t('revenueSources.netDay')}!`,
                      // },
                    ]
                  }
                >
                  <InputNumber label={t('revenueSources.netDay')} size="large" min={0} max={180} />
                </Form.Item>
              ) : (
                <div className={styles.empty} />
              );
            }}
          </Form.Item>
        </FormLayout>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const logo = getFieldValue(RevenueSourceKey.LOGO);

            if (logo) return null;

            return (
              <Upload
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                title="Drag and drop logotype file"
                accept=".svg"
                multiple={false}
                maxCount={1}
                className={styles.upload}
                beforeUpload={(file) => {
                  form.setFieldValue(RevenueSourceKey.LOGO, file);

                  return false;
                }}
              />
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldValue }) => {
            const logo = getFieldValue(RevenueSourceKey.LOGO);

            if (logo)
              return (
                <UploadImage className={styles.uploadImage} onDelete={() => setFieldValue(RevenueSourceKey.LOGO, null)}>
                  {logo instanceof File && (
                    <img className={styles.logoImg} src={URL.createObjectURL(logo)} alt="logo" />
                  )}
                  {typeof logo === 'string' && (
                    <div className={styles.logo} style={{ backgroundImage: `url(${logo})` }} />
                  )}
                </UploadImage>
              );

            return null;
          }}
        </Form.Item>
        {revenueSourceId !== undefined && (
          <>
            <FormLayout>
              <Form.Item
                name={RevenueSourceKey.SYNC_TYPE}
                shouldUpdate
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: `Please select ${t('revenueSources.syncType')}!`,
                    // },
                  ]
                }
              >
                <Select
                  label={t('revenueSources.syncType')}
                  options={REVENUE_SOURCES_SYNC_TYPE_OPTIONS}
                  size="large"
                  disabled={!form.getFieldValue(RevenueSourceKey.CODE)}
                />
              </Form.Item>
              <Form.Item name={RevenueSourceKey.CODE}>
                <InputNumber label={t('global.code')} size="large" disabled />
              </Form.Item>
            </FormLayout>
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => (
                <Description
                  dataSource={[
                    {
                      title: t('revenueSources.connection'),
                      content: getFieldValue(RevenueSourceKey.CODE) ? (
                        <span className={styles.yes}>{RevenueSourceConnectionEnum.YES}</span>
                      ) : (
                        <span className={styles.no}>{RevenueSourceConnectionEnum.NO}</span>
                      ),
                    },
                  ]}
                />
              )}
            </Form.Item>
          </>
        )}
        <div className={styles.actions}>
          <Button htmlType="submit" type="primary">
            {t('global.save')}
          </Button>
          <Button onClick={() => goBack()}>{t('global.cancel')}</Button>
          {revenueSourceId !== undefined && (
            <Button icon={<TrashIcon size={16} />} type="primary" danger onClick={onDelete}>
              {t('global.delete')}
            </Button>
          )}
        </div>
      </Form>
    </MainContent>
  );
};

export default RevenueSources;
