//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Table, DatePicker, EurIcon, UsdIcon } from '@gamesb42/ui-kit';
import { useHistory } from 'react-router-dom';

import { formatAmount } from 'helpers/formatters';
import ContentWrapper from 'components/ContentWrapper';
import { getToken } from 'helpers/token';

import s from './dashboard.module.scss';

interface DashboardDataRes {
  sum_loan_body_eur: string;
  sum_loan_body_usd: string;
  sum_commission_eur: string;
  sum_commission_usd: string;
  value_of_money_eur: string;
  value_of_money_usd: string;
  sum_commission_net_eur: string;
  sum_commission_net_usd: string;
  sum_di_eur: string;
  sum_di_usd: string;
}

const dashboardDatadefault = {
  sum_loan_body_eur: '0',
  sum_loan_body_usd: '0',
  sum_commission_eur: '0',
  sum_commission_usd: '0',
  value_of_money_eur: '0',
  value_of_money_usd: '0',
  sum_commission_net_eur: '0',
  sum_commission_net_usd: '0',
  sum_di_eur: '0',
  sum_di_usd: '0',
};

function Card({ title, usdValue, eurValue }) {
  return (
    <div className={s.card}>
      <div className={s.cardTitle}>{title}</div>
      <div className={s.moneyRow}>
        <EurIcon color="#4E5169" size={24} />
        <div className={s.value}>{formatAmount(parseFloat(eurValue))}</div>
      </div>
      <div className={s.moneyRow}>
        <UsdIcon color="#4E5169" size={24} />
        <div className={s.value}>{formatAmount(parseFloat(usdValue))}</div>
      </div>
    </div>
  );
}

export default function Dashboard() {
  const [dashboardData, setDashboardDate] = useState<DashboardDataRes>(dashboardDatadefault);
  const [dateFrom, setDateFrom] = useState<DatePickerProps['value']>(moment());
  const [portfolioData, setPortfolioData] = useState([]);
  const [portfolioLoading, setPortfolioLoading] = useState(true);
  const history = useHistory();

  const portfolioColumns = [
    {
      title: 'Investor portfolio',
      dataIndex: 'counterparty',
      width: '458px',
      align: 'left' as 'left',
      render: (_, row) => {
        return row.counterparty === 'Total' ? <span className={s.subTitle}>{row.counterparty}</span> : row.counterparty;
      },
    },
    {
      title: '',
      dataIndex: 'outstanding_usd',
      width: '206px',
      align: 'right' as 'right',
      render: (_, row) => {
        return row.counterparty === 'Total' ? (
          <div className={s.total}>
            <UsdIcon />
            {formatAmount(parseFloat(row.outstanding_usd))}
          </div>
        ) : (
          <div
            className={s.link}
            onClick={() =>
              history.push(
                `/workingCapital?order=desc&sortedBy=receiptDate&currency=USD&counterparty=${row.counterparty}`,
              )
            }
          >
            $ {formatAmount(parseFloat(row.outstanding_usd))}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'outstanding_eur',
      width: '206px',
      align: 'right' as 'right',
      render: (_, row) => {
        return row.counterparty === 'Total' ? (
          <div className={s.total}>
            <EurIcon />
            {formatAmount(parseFloat(row.outstanding_eur))}
          </div>
        ) : (
          <span
            className={s.link}
            onClick={() =>
              history.push(
                `/workingCapital?order=desc&sortedBy=receiptDate&currency=EUR&counterparty=${row.counterparty}`,
              )
            }
          >
            € {formatAmount(parseFloat(row.outstanding_eur))}
          </span>
        );
      },
    },
  ];

  function preparePortfolioData(data) {
    const totalRow = { counterparty: 'Total', outstanding_eur: data.total_eur, outstanding_usd: data.total_usd };

    return [...data.items, totalRow];
  }

  function getPortfolio() {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/tranche/portfolio?report_date=${dateFrom.format('yyyy-MM-DD')}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => setPortfolioData(preparePortfolioData(result)))
      .catch(() => {})
      .finally(() => {
        setPortfolioLoading(false);
      });
  }

  useEffect(() => {
    getPortfolio();
  }, []);

  useEffect(() => {
    if (dateFrom) {
      let myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${getToken()}`);

      fetch(`${process.env.REACT_APP_BASE_URL}/reports/dashboard?report_date=${dateFrom.format('yyyy-MM-DD')}`, {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      })
        .then((response) => response.json())
        .then((result) => {
          setDashboardDate(result);
        })
        .catch((error) => console.log('error', error));

      getPortfolio();
    }
  }, [dateFrom, setDateFrom]);

  function calculateHeight(length) {
    if (length > 10) {
      return 800;
    }

    switch (length) {
      case 0: {
        return 100;
      }
      case 1: {
        return 156;
      }
      case 2: {
        return 204;
      }
      default:
        return 100 + (portfolioData.length - 1) * 56;
    }
  }

  return (
    <ContentWrapper style={{ padding: '20px 40px', position: 'relative' }} maxWidthContent fullHeight>
      <div className={s.contentWrapper}>
        <div className={s.center}>
          <div className={s.title}>Dashboard</div>
          {Object.keys(dashboardData).length > 0 && (
            <div className={s.tablesWrapper}>
              <div className={s.firstRow}>
                <DatePicker
                  placeholder="Select month"
                  label={dateFrom ? 'Selected month' : 'Select month'}
                  onChange={setDateFrom}
                  picker="month"
                  format="MMMM yyyy"
                  defaultValue={moment()}
                />
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Card
                    title="Сlient portfolio"
                    usdValue={dashboardData.sum_loan_body_usd}
                    eurValue={dashboardData.sum_loan_body_eur}
                  />
                  <Card
                    title="Cost of money"
                    usdValue={dashboardData.value_of_money_usd}
                    eurValue={dashboardData.value_of_money_eur}
                  />
                </div>
              </div>
              <Card
                title="Accrued commission Gross"
                usdValue={dashboardData.sum_commission_usd}
                eurValue={dashboardData.sum_commission_eur}
              />
              <Card
                title="Accrued commission Net of VAT"
                usdValue={dashboardData.sum_commission_net_usd}
                eurValue={dashboardData.sum_commission_net_eur}
              />
              <Card title="Default Interest" eurValue={dashboardData.sum_di_eur} usdValue={dashboardData.sum_di_usd} />
            </div>
          )}
          {!portfolioLoading && (
            <div style={{ maxWidth: '934px', marginTop: '20px', overflow: 'hidden' }}>
              <Table
                columns={portfolioColumns}
                dataSource={portfolioData}
                className={s.table4}
                style={{ height: `${calculateHeight(portfolioData.length)}px` }}
              />
            </div>
          )}
        </div>
      </div>
    </ContentWrapper>
  );
}
