/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import queryString from 'query-string';
import type { Dictionary } from 'lodash';
import moment from 'moment'

import { ITransactionParamsType } from 'types/TransactionFormTypes';
import { createEndpoint } from 'helpers/formatters';
import { ICompanyParamsType } from 'types/CompanyTypes';
import getParams from 'helpers/getParams';
import { GetUserParamsType } from 'queries/user/getUsers';
import { monthlyInvoiceParamsReportsType } from 'queries/monthlyReport/getMonthlyInvoiceReports';
import { expectedPaymentsParamsType } from 'queries/expectedPayments/getExpectedPayments';
import { commissionReportParamsType } from 'queries/commissionReport/getCommissionReports';
import { ClientAmountParamsT } from 'queries/amount/getClientAmountsReport';
import { PurchaseRequestParamsT } from 'queries/purchaseRequest/getPurchaseRequestList';
import { PivotTransactionParamsT } from 'queries/transaction/getPivotTransactionReports';

const host = window.ENV?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;
const sca = window.ENV?.REACT_APP_SCA_URL || process.env.REACT_APP_SCA_URL;

window.CUSTOM_ENV = { ...(process.env || {}) };

let hostPds = window.ENV?.REACT_APP_PDS_URL || process.env.REACT_APP_PDS_URL;
hostPds = `${hostPds}/v1`;
const hostTransaction = window.ENV?.REACT_APP_TS_URL || `${process.env.REACT_APP_TS_URL}/v1`;
const session = '/session';
const hostV2 = host.replace('api/v1', 'api/v2');
export interface getMonthlyReportRecreateParamsType {
  report_date?: Date;
  document_id?: string;
}

export interface getMonthlyInvoiceRecreateType {
  company_id?: string;
  document_id?: string;
  currency?: string;
  on_date?: Date;
  invoice_id?: string;
  report_date?: Date;
}
interface getMonthlyReportRecreateUrlType {
  params: getMonthlyReportRecreateParamsType;
  companyId: string;
}
interface getMonthlyInvoiceReportDeleteType {
  id: string;
  companyId: string;
}

export interface getMonthlyInvoiceReportCreateParamsType {
  report_type: string;
  current_month?: boolean;
}
interface getMonthlyInvoiceReportCreateType {
  params: getMonthlyInvoiceReportCreateParamsType;
  companyId: string;
}

export interface amountCellAdjustmentType {
  order_id: string;
  date: string;
  adjustment_type: string;
}

export default {
  getCreatePlatformUrl: () => `${hostPds}/stores`,
  // users
  getMeUrl: () => `${sca}/v1/auth/me`,
  getRefreshTokenUrl: () => `${sca}/v1/auth/refresh`,
  getUserScaUrl: (userId) => `${sca}/v1/users/${userId}`,
  getUserCompanyScaUrl: (userId) => `${sca}/v1/users/${userId}/companies`,
  getUsersScaUrl: (params?) => `${sca}/v1/users?${queryString.stringify(params)}`,
  getAddCompanyForUser: (userId: string, companyId: string) =>
    `${sca}/v1/users/${userId}/companies?company-id=${companyId}`,
  getRemoveCompanyForUser: (userId: string, companyId: string) => `${sca}/v1/users/${userId}/companies/${companyId}`,
  getSetActiveCompanyForUser: (userId: string, companyId: string) =>
    `${sca}/v1/users/${userId}/companies/current?company-id=${companyId}`,
  getNeedRepaymentPenaltyUrl: (company_id: string) => `${host}/purchases/now/need_repayment_penalty/${company_id}`,
  getPlatformByClaimsUrl: () => `${host}/platform/by_claims`,
  getAdjustmentCalc: (id: string, params: Object) => `${host}/activation_order/${id}/adjustment${getParams(params)}`,
  setAdjustment: (params: Object) => `${host}/adjustment${getParams(params)}`,
  delAdjustment: (id: string) => `${host}/adjustment/${id}`,
  getAmountCellAdjustment: (params: amountCellAdjustmentType) => `${host}/order_amount/cell${getParams(params)}`,
  getReconciliationReport: ({ id, ...restParams }) =>
    `${host}/maintenance/reconciliation_report/${id}/file${getParams(restParams)}`,
  getAuthUrl: () => host + session,
  getCreateTransactionUrl: () => `${host}/transaction`,
  getCreateOrderAmountUrl: () => `${host}/order_amount`,
  getTransactionsUrl: (companyId: string, currency: string, showDeleted: boolean, tx_type: string) => {
    let endpoint = `${host}/transaction?_end=10&show_deleted=${showDeleted}&_order=ASC&_sort=id&_start=0`;

    if (companyId) {
      endpoint += `&company_id=${companyId}`;
    }

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    if (tx_type) {
      endpoint += `&tx_type=${tx_type}`;
    }

    return endpoint;
  },
  getTransactionsForAdjustingUrl: (companyId: string, currency: string) => {
    let endpoint = `${host}/transactions_for_adjusting`;

    if (companyId) {
      endpoint += `/?company_id=${companyId}`;
    }

    if (currency) {
      endpoint += companyId ? `&currency=${currency}` : `/?currency=${currency}`;
    }

    return endpoint;
  },
  getTransactionsUrlV2: ({
    company_id,
    currency,
    show_deleted,
    tx_type,
    status,
    date_from,
    date_to,
    company_name,
    contract_number,
    limit,
    page,
    order_id,
  }: ITransactionParamsType) => {
    const endpoint = `${hostV2}/transaction?page=${page}&show_deleted=${show_deleted}&limit=${limit}`;

    return createEndpoint(endpoint, {
      company_id,
      currency,
      tx_type,
      status,
      date_from,
      date_to,
      company_name,
      contract_number,
      order_id,
    });
  },
  getSubTransactionUrl: () => `${host}/subtransaction`,
  getTransactionUrl: (id) => `${host}/transaction/${id}`,
  getFactorDetailsUrl: () => `${host}/settings/factor_details`,
  setSyncTrUrl: (params) => `${host}/sync/transactions${getParams(params)}`,
  createListTransactionsUrl: () => `${hostTransaction}/transactions/`,
  downloadArchiveTransactionURL: (params) => `${hostTransaction}/transactions/export${getParams(params)}`,
  getArchiveTransactionsUrl: (params) => `${hostTransaction}/transactions/archive/${getParams(params)}`,
  getAccountsByIdCompanyUrl: (company_id, params?) =>
    `${hostTransaction}/accounts/company-id/${company_id}${getParams(params)}`,
  getListTransactionsUrl: (params?: object) => `${hostTransaction}/transactions/${getParams(params)}`,
  getCurrencyRateUrl: (params?: object) => `${host}/currency_rate${getParams(params)}`,
  setTransactionsUrl: (transaction_id) => `${hostTransaction}/transactions/${transaction_id}`,
  getTransactionsByIdUrl: (transaction_id, params) =>
    `${hostTransaction}/transactions/${transaction_id}${getParams(params)}`,
  deleteTransactionsByIdUrl: (transaction_id) => `${hostTransaction}/transactions/${transaction_id}`,
  getTransactionsRelatedByIdUrl: (transaction_id) => `${hostTransaction}/transactions/${transaction_id}/related`,
  getEntriesUrl: (id) => `${host}/transaction_entries/${id}`,
  getActivationOrdersUrl: (companyId: string, showDeleted: boolean) => {
    let endpoint = `${host}/activationOrder?_end=10&_order=ASC&_sort=id&_start=0&show_deleted=${showDeleted}`;

    if (companyId) {
      endpoint += `&company_id=${companyId}`;
    }

    return endpoint;
  },
  getActivationOrdersUrlV2: (params) => `${hostV2}/activationOrder${getParams(params)}`,
  getActivationOrdersRecalculateUrl: () => `${host}/maintenance/activation_orders/recalculate`,
  getExchangeRatesUrl: () => `${host}/system_settings/load_cur_rates`,
  getActivationOrderUrl: (id, type = 'activationOrder') => `${host}/${type}/${id}`,
  getActivationOrderUrlApplaud: (id) => `${host}/activation_order_applaud/${id}`,
  getCreateActivationOrderUrl: () => `${host}/activationOrder`,
  getCreateAvailableReceivablesUrl: () => `${host}/available_receivables`,
  getCreateFutureReceivablesUrl: () => `${host}/future_receivables`,
  getCreateScheduleFutureReceivablesUrl: () => `${host}/future_receivables_payment_schedule`,
  getEditAvailableReceivablesUrl: (id) => `${host}/available_receivables/${id}`,
  getEditAvailableReceivablesListUrl: (id) => `${host}/available_receivables?company_id=${id}`,
  getEditFutureReceivablesUrl: (id) => `${host}/future_receivables/${id}`,
  getScheduleForFutureReceivablesUrl: (id) => `${host}/future_receivables_payment_schedule/${id}`,
  getCreateActivationOrderUrlApplaud: () => `${host}/activation_order_applaud`,
  getActivationOrderApplaudCalculateUrl: () => `${host}/activation_order_applaud_calculate`,
  getActivationOrderTechnicalInfoUrl: (id) => `${host}/order_amount/${id}`,
  getActivationOrderCalculateStatusUrl: (id) => `${host}/activation_order/${id}/calc_status`,
  getRecalculateAOTechnicalInfoUrl: (id) => `${host}/activation_order/${id}/calculate`,
  getActivationOrderCreationUrl: (id) => `${host}/activationOrderCreationData/${id}`,
  getActivationOrderListUrl: (
    company_id: string,
    show_deleted: boolean,
    contract_number: string,
    currency: string,
    _sort: string,
  ) => {
    let endpoint = `${host}/activation_order_list?_end=10&_order=ASC&_sort=id&_start=0&show_deleted=${show_deleted}`;

    if (company_id) {
      endpoint += `&company_id=${company_id}`;
    }

    if (contract_number) {
      endpoint += `&contract_number=${contract_number}`;
    }

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    if (_sort) {
      endpoint += `&_sort=${_sort}`;
    }

    return endpoint;
  },
  getCreateActivationOrderDocumentUrl: () => `${host}/activation_order_document`,
  getAODocumentXLSX: (id: string) => `${host}/activation_order/${id}/xlsx`,
  getCommissionReportsUrl: (params: commissionReportParamsType) => `${host}/interests_report${getParams(params)}`,

  getPurchaseRequestListURL: (params?: PurchaseRequestParamsT) => `${host}/purchase_request${getParams(params)}`,
  getPurchaseRequestUrl: (id) => `${host}/purchase_request/${id}`,
  deletePurchaseRequestUrl: (id) => `${host}/purchase_request/${id}`,
  getAvailableAdvanceUrl: (params) => `${host}/purchase_request/available_advance${getParams(params)}`,
  getNextNumberUrl: (params) => `${host}/purchase_request/next_number${getParams(params)}`,
  checkNumberUrl: (params) => `${host}/purchase_request/check_number${getParams(params)}`,
  setPurchaseRequestStatus: (id?: string) => `${host}/purchase_request/${id}/status`,
  createPurchaseRequestUrl: () => `${host}/purchase_request`,
  editPurchaseRequestUrl: (id: string) => `${host}/purchase_request/${id}`,
  getCreateSignedPurchaseDocumentUrl: (id: string) => `${host}/purchase_request/${id}/upload_file`,

  getTransactionReportsUrl: (dateFrom: string, dateTo: string, companyId: string, currency: string) => {
    let endpoint = `${host}/transaction_report?_end=10&_order=ASC&_sort=id&_start=0&date_from=${dateFrom}&date_to=${dateTo}`;

    if (companyId) {
      endpoint += `&company_id=${companyId}`;
    }

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    return endpoint;
  },
  getDetailedTransactionReportsUrl: (companyId: string, currency: string) => {
    let endpoint = `${host}/transaction_report_detailed?_end=10&_order=ASC&_sort=id&_start=0`;

    if (companyId) {
      endpoint += `&company_id=${companyId}`;
    }

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    return endpoint;
  },
  getDetailedTransactionReportTargetsUrl: (companyId: string, currency: string) => {
    let endpoint = `${host}/transaction_report_detailed_targets`;

    if (companyId) {
      endpoint += `/?company_id=${companyId}`;
    }

    if (currency) {
      endpoint += companyId ? `&currency=${currency}` : `/?currency=${currency}`;
    }

    return endpoint;
  },
  getTransactionTargetsReportsUrl: (companyId: string, currency: string) => {
    let endpoint = `${host}/transaction_report_flat?_end=10&_order=ASC&_sort=id&_start=0`;

    if (companyId) {
      endpoint += `&company_id=${companyId}`;
    }

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    return endpoint;
  },

  getMonthlyReportsUrl: (dateFrom: string, dateTo: string, companyId: string) => {
    let endpoint = `${host}/monthly_report?_end=10&_order=ASC&_sort=id&_start=0&date_from=${dateFrom}&date_to=${dateTo}`;

    if (companyId) {
      endpoint += `&company_id=${companyId}`;
    }

    return endpoint;
  },
  getMonthlyInvoiceReportsUrl: (id: string, params: monthlyInvoiceParamsReportsType) =>
    `${host}/monthly_invoice_reports/${id}${getParams(params)}`,
  getExpectedPaymentsUrl: (params: expectedPaymentsParamsType | Dictionary<expectedPaymentsParamsType>) =>
    `${host}/reports/expected_payments_classic${getParams(params)}`,
  getAccountBalanceUrl: (dateFrom: string, dateTo: string, currency: string) => {
    let endpoint = `${host}/account_balance_report?_end=10&_order=ASC&_sort=id&_start=0&date_from=${dateFrom}&date_to=${dateTo}`;

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    return endpoint;
  },
  getAccountStatementUrl: (dateFrom: string, dateTo: string, company_id: string, currency: string) => {
    let endpoint = `${host}/account_statement_report?_end=10&_order=ASC&_sort=id&_start=0&date_from=${dateFrom}&date_to=${dateTo}`;

    if (company_id) {
      endpoint += `&company_id=${company_id}`;
    }

    if (currency) {
      endpoint += `&currency=${currency}`;
    }

    return endpoint;
  },
  getAccrualReportUrl: (params: object): string => `${host}/accrual_report${getParams(params)}`,
  getAccrualReportUrlcsv: (params: object): string => `${host}/accrual_report/export_to_csv${getParams(params)}`,
  getClientAmountsReportUrl: (params: ClientAmountParamsT) => `${host}/client_amounts_report${getParams(params)}`,
  getPivotTransactionsUrl: (params: PivotTransactionParamsT) =>
    `${host}/pivot_transaction_report${getParams(params)}&_end=10&_order=ASC&_sort=id&_start=0`,
  getPrincipalAmountsUrl: (dateFrom: string, dateTo) =>
    `${host}/principal_amounts_report?_end=10&_order=ASC&_sort=id&_start=0&date_from=${dateFrom}&date_to=${dateTo}`,
  getCompany: () => `${host}/company?_end=25&_order=DESC&_sort=id&_start=0&show_deleted=${showDeleted}`,
  getCompanyUrlV1: (id?: string) => `${sca}/v1/companies${id ? `/${id}` : ''}`,
  getFactorCompanyScaUrl: () => `${sca}/v1/companies/factor`,
  getStatusCompanyUrl: (companyId, params) =>
    `${sca}/v1/companies/${companyId}/status?${queryString.stringify(params)}`,
  getCompaniesUrl: (showDeleted) => `${host}/company?_end=25&_order=DESC&_sort=id&_start=0&show_deleted=${showDeleted}`,
  getCompaniesScaUrl: (id, params?) => `${sca}/v1/companies${id ? `/${id}` : ''}?${queryString.stringify(params)}`,
  getCompanySyncSca: (id) => `${host}/companies/${id}/sync`,
  getCompaniesV2Url: ({ showDeleted, limit, page, sortedBy, order }: ICompanyParamsType) =>
    `${host}/company?&page=${page}&_order=${order}&_sort=${sortedBy}&show_deleted=${showDeleted}&limit=${limit}`,
  getCompaniesUrlV3: ({ type, limit, page, sortedBy, order, status, companyName, group }: ICompanyParamsType) => {
    let endpoint = `${hostV2}/company?page=${page}&_order=${order?.toUpperCase()}`;

    if (limit && limit > 0) {
      endpoint += `&limit=${limit}`;
    }

    if (type) {
      endpoint += `&type=${type}`;
    }

    if (status) {
      endpoint += `&status=${status}`;
    }

    if (sortedBy) {
      endpoint += `&_sort=${sortedBy}`;
    }

    if (companyName) {
      endpoint += `&company_name=${companyName}`;
    }
    group && (endpoint += `&groups=${group}`);

    return endpoint;
  },
  getCompanyUrl: (id) => `${host}/company/${id}`,
  getCompanyGroupUrl: (id) => `${host}/company/${id}/group`,
  getCompanyCreateUrl: () => `${host}/company`,
  getCompanyListUrl: (params) => `${hostV2}/company${getParams(params)}`,
  getUsersUrl: (params: GetUserParamsType) => `${host}/user${getParams(params)}`,
  getMonthlyReportRecreateUrl: ({ params, companyId }: getMonthlyReportRecreateUrlType) =>
    `${host}/monthly_report_recreate/${companyId}${getParams(params)}`,
  getMonthlyInvoiceRecreateUrl: (params: getMonthlyInvoiceRecreateType) =>
    `${host}/invoice_report_recreate${getParams(params)}`,
  getMonthlyInvoiceReportDeleteUrl: ({ id, companyId }: getMonthlyInvoiceReportDeleteType) =>
    `${host}/monthly_invoice_reports/${companyId}?file_id=${id}`,
  getMonthlyInvoiceReportCreateUrl: ({ params, companyId }: getMonthlyInvoiceReportCreateType) =>
    `${host}/monthly_invoice_reports/${companyId}${getParams(params)}`,
  getUsersFilterUrl: (params: GetUserParamsType) => `${hostV2}/user${getParams(params)}`,
  getUserCreateUrl: () => `${host}/user`,
  getUserUrl: (id) => `${host}/user/${id}`,
  getCheckInQueueListUrl: (status) =>
    `${host}/signup/sign_up_status?_end=25&_order=DESC&_sort=id&_start=0&status=${status}`,
  getCheckInQueueUrl: (id) => `${host}/signup/sign_up_status/${id}`,
  getAdminsUrl: (show_deleted) => `${host}/admin?_end=25&_order=DESC&_sort=id&_start=0&show_deleted=${show_deleted}`,
  getAdminCreateUrl: () => `${host}/admin`,
  getAdminUrl: (id) => `${host}/admin/${id}`,
  getUploadAllDataUrl: () => `${host}/load_old_data`,
  getCancelUploadAllDataUrl: () => `${host}/ipc/cancel/load_old_data`,
  getImportedDataUrl: () => `${host}/import_data`,
  getCancelImportedDataUrl: () => `${host}/ipc/cancel/import_data`,
  getSyncAccountUrl: () => `${host}/sync_account/edit`,
  getSystemSettingsUrl: (block_name) => `${host}/system_settings/edit/${block_name}`,
  getContractsUrl: (id) => `${hostV2}/contracts/${id}`,
  getContractsUrlV2: (id) => `${hostV2}/contracts/${id}`,
  getRevenueSourcesUrl: (id?: string) => `${hostPds}/stores${id ? `/${id}` : ''}`,
  getSyncPdsUrl: () => `${host}/execute_task/sync_pds`,
  getCreateAvailableAdvancePlatformUrl: () => `${host}/load_platform_amounts_data`,
  getPlatformsUrl: (params: object): string => `${hostPds}/stores${getParams(params)}`,
  getPlatformsFactoringUrl: (): string => `${host}/platform`,
  getPlatformByIdUrl: (id) => `${hostPds}/stores/${id}`,
  getContractCreateUrl: () => `${host}/customer_contract/`,
  delCustomerContractUrl: ({ id }: { id: string }) => `${host}/customer_contract/${id}`,
  setCustomerContractUrl: ({ id }: { id: string }) => `${host}/customer_contract/${id}`,
  getProcessStatusUrl: (process_name) => `${host}/ipc/${process_name}`,
  getAOByCompanyUrl: (company_id) => `${host}/company/${company_id}/ao`,
  getAOByContractUrl: (contract_id) => `${host}/contracts/${contract_id}/ao`,
  getAddAOFileUrl: (company_id, ao_id) => `${host}/company/${company_id}/ao/${ao_id}/file`,
  getUploadStorageFileUrl: () => `${host}/file_storage`,
  getStorageFileUrl: (file_id) => `${host}/file_storage/${file_id}`,
  getDeleteStorageFileUrl: (file_id) => `${host}/file_storage/${file_id}`,
  getEditStorageFileUrl: (file_id) => `${host}/file_storage/${file_id}`,
  getCountriesUrl: (): string => `${host}/countries`,
  getContractUrl: (company_id): string => `${host}/customer_contract/pdf/${company_id}`,
  getAbout: () => `${host}/about`,
  getPurchaseClaimsUrl: (params) => `${host}/purchases/now/claims${getParams(params)}`,
  getPdsAccountsUrl: (params) => `${hostPds}/accounts${getParams(params)}`,
  uploadPdsRevenueFileUrl: (id) => `${hostPds}/revenue/upload-file/${id}`,
  getStatusRevenueUploadFileUrl: (id: string): string => `${hostPds}/revenue/upload-file/status/${id}`,
  getRevenueByDaysUrl: (company_id, currency, params) =>
    `${hostPds}/revenue/by-days/${company_id}/${currency}${getParams(params)}`,
  getPurchaseNowPlatformsUrl: (params) => `${host}/purchases/now/platforms${getParams(params)}`,
  getNextAONumber: (params: object, check: boolean): string =>
    `${host}/next_ao_number${check ? '/check' : ''}${getParams(params)}`,
  updateAggregationTableUrl: (params: object): string =>
    `${host}/maintenance/update_aggregation_table${getParams(params)}`,
  getAggregationStatusUrl: (): string => `${host}/maintenance/update_aggregation_table/status`,
  createTransactionInToClaimUrl: (): string => `${host}/transaction/create_transaction_in_to_claim`,
  connectPlatformUrl: (): string => `${hostPds}/accounts`,
  getAccountsUrl: (params: object): string => `${hostTransaction}/accounts?${queryString.stringify(params)}`,
  getAccountsByOrderIdUrl: (orderId) => `${hostTransaction}/accounts/order-id/${orderId}`,
  getUploadInvoicesUrl: (invoiceId: string) => `${host}/invoice/${invoiceId}/file`,
  createAgreement: (companyId: string) =>`${host}/agreement/company/${companyId}?agreement_date=${moment().format('YYYY-MM-DD')}&file_format=pdf`,
  getAvailableReceivablesUrl: (orderId, params) =>
    `${host}/activation_order/available_receivables/${orderId}/status?${queryString.stringify(params)}`,
};
