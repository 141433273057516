//@ts-nocheck
import React, { useState } from 'react';
import { Modal, Select, Input, DoneRoundIcon, CloseBigIcon } from '@gamesb42/ui-kit';
import { Form } from 'antd';

import CompanySelect from 'components/CompanySelect';
import useAlert from 'hooks/api/useAlert';

import s from './style.module.scss';

export default function AddModal({ onClose, getData }) {
  const { setAlert } = useAlert();
  const [form] = Form.useForm();

  const handleCreate = async (values) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw =
      values.company?.length > 0
        ? JSON.stringify({
            bank_name: values.bank,
            currency: values.currency,
            iban_number: values.iban,
            company_id: values.company,
          })
        : JSON.stringify({ bank_name: values.bank, currency: values.currency, iban_number: values.iban });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    return fetch(`${process.env.REACT_APP_SCA_URL}/v1/subaccounts/`, requestOptions)
      .then((res) => {
        res.ok
          ? setAlert({
              type: 'success',
              message: (
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <DoneRoundIcon size="24" color="#26B634" />
                  IBAN *{values.iban.substr(values.iban.length - 3)} was created successful
                  <CloseBigIcon size="24" />
                </div>
              ),
            })
          : setAlert({
              type: 'error',
              message: 'Something went wrong please try later.',
            });
      })
      .then(() => getData())
      .catch(() => {})
      .finally(() => {
        onClose();
      });
  };

  return (
    <Modal
      open
      title="Create IBAN"
      okButtonProps={{
        onMouseDown: () => {
          form.submit();
          // handleCreate();
        },
      }}
      onCancel={onClose}
      okText="Create"
      cancelText="Cancel"
      width="838px"
    >
      <Form form={form} onFinish={handleCreate} autoComplete="off">
        <div className={s.formField}>
          <Form.Item
            name="bank"
            rules={[
              {
                required: true,
                message: 'Please select bank!',
              },
            ]}
          >
            <Select
              style={{ marginBottom: 'none' }}
              size="large"
              label="Bank"
              onChange={(e) => {
                form.setFieldsValue({
                  bank: e,
                });
              }}
              allowClear
            >
              <Select.Option value="Unlimint">Unlimint</Select.Option>
              <Select.Option value="Ecomm">Ecomm</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <div style={{ display: 'flex', gap: '32px' }}>
          <div style={{ width: '150px' }}>
            <Form.Item
              name="currency"
              rules={[
                {
                  required: true,
                  message: 'Please select currency!',
                },
              ]}
            >
              <Select
                width="150px"
                size="large"
                label="Currency"
                onChange={(e) => {
                  form.setFieldsValue({
                    currency: e,
                  });
                }}
                allowClear
              >
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div style={{ width: '100%' }}>
            <Form.Item
              name="iban"
              rules={[
                {
                  required: true,
                  message: 'Please select iban!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const currentBank = form.getFieldValue('bank');
                    const minIbanLength = currentBank && currentBank === 'Unlimint' ? 4 : 3;

                    if (value?.length < minIbanLength) {
                      return Promise.reject(new Error(`Minimum length ${minIbanLength}`));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                size="large"
                label="IBAN"
                onChange={(e) => {
                  form.setFieldsValue({
                    iban: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Form.Item name="company">
            <CompanySelect
              size="large"
              label="Company"
              onChange={(e) => {
                form.setFieldsValue({
                  company: e,
                });
              }}
              allowClear
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
