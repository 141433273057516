import React, { FC } from 'react';

import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';

import ItemsAo from '../ItemsAo';
import { paramsObjAO } from '../../ActivationOrderForm';

interface CardClassicType {
  params?: paramsObjAO;
}
const styles = { height: '100%', margin: 0 };

const CardClassic: FC<CardClassicType> = React.memo(() => {
  const { listSwitch }: useCompanyType = useCompany();

  return (
    <>
      <ItemsAo types={[KeyCardsEnum.AO_DATE, KeyCardsEnum.AO_NUMBER]} />
      <ItemsAo types={[KeyCardsEnum.CURRENCY, KeyCardsEnum.INTEREST]} />
      <ItemsAo types={[KeyCardsEnum.AMOUNT, KeyCardsEnum.MOCK_PURCHASED_RECEIVABLES, KeyCardsEnum.PERIOD]} />
      <ItemsAo types={[KeyCardsEnum.PURCHASE_DATE, KeyCardsEnum.REPAYMENT_DATE]} />
      <ItemsAo types={[KeyCardsEnum.REVERSE_AMOUNT, KeyCardsEnum.PENALTY_POLICY]} />
      <ItemsAo types={[KeyCardsEnum.TRANSACTION_SECURITY]} />
      <ItemsAo types={[KeyCardsEnum.PURPOSE]} />

      <ItemsAo types={[KeyCardsEnum.CUSTOM_SETTINGS]} />
      {/* @ts-ignore */}
      {listSwitch.custom_settings && (
        <>
          <ItemsAo style={styles} types={[KeyCardsEnum.PAYMENT_DAYS]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.GRACE_PERIOD]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.COMMISSION_DISABLE_DATE]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.CAPITALIZATION_PERIOD_DISABLED]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.CAPITALIZATION_DISABLE_DATE]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE]} />
        </>
      )}
    </>
  );
});

export default CardClassic;
