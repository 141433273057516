import React, { useState, useRef, useEffect, useMemo, FC, memo, ReactNode } from 'react';
import { Table as TableAntd, TableProps as TableAntdProps, ConfigProvider } from 'antd';
import type { ColumnGroupType, ColumnType } from 'antd/es/table';
import classNames from 'classnames';

// import { Skeleton, SkeletonProps } from '../Skeleton';
// import { Body4 } from '../Typography';

import styles from './styles.module.scss';

// eslint-disable-next-line no-shadow
const enum EmptyTextEnum {
  noData,
  noDataFilter,
  noDataSelectedPeriod,
}

const EmptyText: FC<{ type?: EmptyTextEnum }> = memo(({ type }) => {
  let text;

  switch (type) {
    case EmptyTextEnum.noData:
      text = 'There are no data yet';
      break;
    case EmptyTextEnum.noDataFilter:
      text = 'There are no data with filter applied';
      break;
    case EmptyTextEnum.noDataSelectedPeriod:
      text = 'You have no data for the selected period';
      break;
    default:
      text = 'There are no data';
  }

  // return <Body4 className={styles.noData}>{text}</Body4>;
  return <div className={styles.noData}>{text}</div>;
});

// type ColumnsType<RecordType> = ((ColumnGroupType<RecordType> | ColumnType<RecordType>) & {
//   skeletonProps?: SkeletonProps;
//   skeletonRender?: ReactNode;
//   noSkeleton?: boolean;
// })[];

interface TableProps<RecordType> extends Omit<TableAntdProps<RecordType>, 'pagination' | 'columns'> {
  className?: string;
  initialHeight?: string;
  size?: 'middle' | 'small';
  columns: any; //ColumnsType<RecordType>;
  loadingRowCount?: number;
  emptyTextType?: EmptyTextEnum;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DailyRevenueTable = <RecordType extends object = any>({
  className,
  rowKey,
  loading,
  columns: initialColumns,
  loadingRowCount = 10,
  size = 'middle',
  initialHeight = '800px',
  emptyTextType,
  dataSource: initialDataSource,
  ...props
}: TableProps<RecordType>) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [heightTable, setHeightTable] = useState(initialHeight);

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (!loading) return initialColumns.map(({ ...column }) => column);

    // return initialColumns.map(({ skeletonProps, skeletonRender, noSkeleton, ...column }) => ({
    //   ...column,

    //   render: () => {
    //     if (noSkeleton) return null;

    //     // eslint-disable-next-line react/jsx-props-no-spreading
    //     return skeletonRender || <Skeleton {...skeletonProps} />;
    //   },
    // }));
  }, [initialColumns, loading]);

  const dataSource = useMemo(() => {
    if (loading)
      return Array.from(Array(loadingRowCount).keys()).map((index) => ({
        [(rowKey as string) || 'key']: index,
      })) as RecordType[];

    return initialDataSource;
  }, [initialDataSource, loading, loadingRowCount, rowKey]);

  return (
    <ConfigProvider renderEmpty={() => <EmptyText type={emptyTextType} />}>
      <div className={classNames(styles.root, className)} ref={rootRef}>
        <TableAntd<RecordType>
          size={size === 'middle' ? undefined : 'small'}
          pagination={false}
          scroll={{ y: heightTable }}
          rowKey={rowKey}
          columns={columns}
          dataSource={dataSource}
          {...props}
        />
      </div>
    </ConfigProvider>
  );
};

export default DailyRevenueTable;
