// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import { SelectKit } from 'components/uiKit/SelectKit';
import { AmountKit } from 'components/uiKit/AmountKit';
import { CurrencyType } from 'components/uiKit/InputKit';
import Button from 'components/uiKit/Button';
import DateInput from 'components/inputs/DateInput';
import { ErrorT } from 'components/forms/PurchaseRequestForm/Form/data';

import styles from './styles.module.scss';
import { RangePicker, RangePickerProps } from '@gamesb42/ui-kit';

const basketSvg = require('assets/img/basket.svg').default;

export type ClaimT = {
  platform_id: string;
  currency: CurrencyType | '';
  due_date: string;
  available_receivables: string;
};

type PropsT = {
  errors: Partial<Record<keyof ClaimT, ErrorT>>;
  currentClaim: ClaimT;
  currentIndex: number;
  setErrors: any;
  setParams: any;
  isHideDeleteButton: boolean;
  isEdit?: boolean;
  backError?: any;
  setBackError?: any;
};

const Claim: FC<PropsT> = (props) => {
  const {
    currentClaim,
    currentIndex,
    errors,
    setErrors,
    setParams,
    isHideDeleteButton,
    isEdit,
    backError,
    setBackError,
  } = props;

  const { availableAdvanceData } = usePurchaseRequest();
  const { t } = useTranslation();
  const isCreate = window.location.href.includes('create');
  const [rangePickerData, setRangePickerData] = useState<RangePickerProps['value']>(
    isEdit && currentClaim?.period_from && currentClaim?.period_to
      ? [moment(currentClaim.period_from, 'YYYY-MM-DD'), moment(currentClaim.period_to, 'YYYY-MM-DD')]
      : [],
  );
  const [claimPlatform, setClaimPlatform] = useState(
    !isCreate ? availableAdvanceData.platforms.find((el) => el.platform_id === currentClaim?.platform_id) : {},
  );

  const platformData = availableAdvanceData.platforms.find(
    ({ platform_id }) => platform_id === currentClaim.platform_id,
  );

  const changeClaim = (value: any, key: keyof ClaimT) => {
    setErrors((prevErrors) => {
      const claimErrors = prevErrors.available_advance;
      claimErrors[currentIndex] = { [key]: !value ? { message: 'Required', isError: true } : undefined };

      // if (key === 'period' && Array.isArray(value) && value[0].diff(value[1], 'days') === 0) {
      //   claimErrors[currentIndex] = { period: { message: 'Period is wrong', isError: true } };
      // }

      return { ...prevErrors, available_advance: claimErrors };
    });

    setParams((prevData) => {
      return {
        ...prevData,
        available_advance: prevData.available_advance.map((claim, index) => {
          if (currentIndex === index && key === 'period' && value === null) {
            return { ...claim, period_from: null, period_to: null };
          }

          if (currentIndex === index && key === 'period' && Array.isArray(value) && value.length === 2) {
            setBackError({});
          }

          return currentIndex === index
            ? key === 'period' && Array.isArray(value) && value.length === 2
              ? {
                  ...claim,
                  period_from: value[0]?.format('YYYY-MM-DD'),
                  period_to: value[1]?.format('YYYY-MM-DD'),
                }
              : {
                  ...claim,
                  period_from: rangePickerData?.length > 1 ? rangePickerData[0]?.format('YYYY-MM-DD') : null,
                  period_to: rangePickerData?.length > 1 ? rangePickerData[1]?.format('YYYY-MM-DD') : null,
                  [key]: value,
                }
            : claim;
        }),
      };
    });
  };

  const deleteClaim = () => {
    setParams((params) => {
      const clone = [...params.available_advance];
      const deletedClaim = clone.splice(currentIndex, 1);

      return {
        ...params,
        available_advance: clone,
        purchase_price: params.purchase_price - deletedClaim[0].purchase_price,
      };
    });
  };

  let needBackError;
  try {
    needBackError = [backError?.claim_period, backError?.exists_period].includes(
      `${rangePickerData[0]?.format('YYYY-MM-DD')} - ${rangePickerData[1]?.format('YYYY-MM-DD')}`,
    );
  } catch (e) {
    needBackError = false;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumn}>
        <SelectKit
          withAllOption={false}
          label={t('forms.purchaseRequest.createForm.distributionPlatform')}
          options={availableAdvanceData.platforms}
          val={claimPlatform?.platform}
          // val={platformData}
          keyObj="platform"
          changeSelectItem={(_, data) => {
            changeClaim(data.platform_id, 'platform_id');
            setClaimPlatform(data);
          }}
          isError={errors?.platform_id?.isError}
          helperText={errors?.platform_id?.message}
          wrapProps={{ height: 60 }}
          startAdornment={
            platformData?.platform_logo && claimPlatform?.platform ? (
              <img className={styles.inputImg} src={platformData.platform_logo} alt={platformData.platform} />
            ) : undefined
          }
        />
        <AmountKit
          // isNeedHandlePaste
          label="Available Receivables"
          onChange={(e) => changeClaim(e.target.value, 'available_receivables')}
          amount={currentClaim.available_receivables ?? ''}
          currency={currentClaim.currency || null}
          wrapProps={{ height: 60 }}
          isError={errors?.available_receivables?.isError}
          helperText={errors?.available_receivables?.message}
        />
        <DateInput
          // isNotErrorLocal
          date={currentClaim.due_date}
          labelKey="forms.purchaseRequest.createForm.dueDate"
          onChangeDateHandler={({ target: { value } }) => changeClaim(value, 'due_date')}
          stylingLabel={{ paddingLeft: 8 }}
          inputStyle={{ paddingLeft: 17 }}
          commonStyle={{ height: 60 }}
          // isError={errors?.due_date?.isError}
          // errorText={errors?.due_date?.message}
        />
        <div>
          <RangePicker
            size="large"
            value={rangePickerData}
            label="Period"
            onChange={(e) => {
              changeClaim(e, 'period');
              setRangePickerData(e);
            }}
            status={needBackError ? 'error' : ''}
          />
          {needBackError && <div className={styles.errorText}>Period is wrong</div>}
        </div>
      </div>
      <Button gutters={[10]} className={styles.deleteButton} onClick={() => deleteClaim()}>
        <img src={basketSvg} alt="basket" onClick={() => {}} />
      </Button>
    </div>
  );
};

export default Claim;
