//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { AddIcon, Button, Select, Table } from '@gamesb42/ui-kit';

import Status from 'components/common/Status';
import AddModal from './components/AddModal';
import EditModal from './components/EditModal';
import CompanySelect from 'components/CompanySelect';
import { updateTitle } from 'helpers/common';
import { getToken } from 'helpers/token';

import s from './styles.module.scss';

const columns = [
  {
    title: 'Bank',
    dataIndex: 'bank_name',
    sorter: {
      compare: (a, b) => a.bank_name.localeCompare(b.bank_name),
      multiple: 1,
    },
  },
  {
    title: 'IBAN',
    dataIndex: 'iban_number',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
  },
  {
    title: 'Client',
    dataIndex: 'company_name',
    sorter: {
      compare: (a, b) => a?.company_name?.localeCompare(b?.company_name),
      multiple: 2,
    },
    render: (_, { company_name }) => {
      return company_name?.length > 0 ? company_name : '-';
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '160px',
    align: 'center',
    sorter: {
      compare: (a, b) => a?.status?.localeCompare(b?.status),
      multiple: 3,
    },
    render: (_, { status }) => {
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '88px' }}>
            <Status status={status.toUpperCase()} style={{ width: '100%' }} />
          </div>
        </div>
      );
    },
  },
];

export default function SubAccountPage() {
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();
  const [value3, setValue3] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [activeRecord, setActiveRecord] = useState({});

  const getData = async () => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${getToken()}`);

    return fetch(`${process.env.REACT_APP_SCA_URL}/v1/subaccounts/?only_available=false`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result);
        setFilteredData(result);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const getDataClear = async () => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${getToken()}`);

    return fetch(`${process.env.REACT_APP_SCA_URL}/v1/subaccounts/?only_available=false`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result);

        let newTableData = result;

        // bank filter
        if (value2?.length > 0) {
          newTableData = newTableData.filter((el) => el.bank_name === value2);
        }
        // status filter
        if (value3?.length > 0) {
          newTableData = newTableData.filter((el) => el.status === value3);
        }

        setFilteredData(newTableData);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    updateTitle('Sub accounts');
    getData();
  }, []);

  return (
    <>
      <div style={{ maxWidth: '934px' }}>
        <div className={s.title}>
          <h1>Sub accounts</h1>
          <Button icon={<AddIcon size={16} />} type="primary" onClick={() => setIsOpenAddModal(true)}>
            Create IBAN
          </Button>
        </div>
        {!loading && (
          <>
            <div className={s.actionBar}>
              <div style={{ width: 470 }}>
                <CompanySelect
                  size="middle"
                  label="Search"
                  value={value1}
                  onChange={(id) => {
                    setValue1(id);
                    let newTableData = data;

                    // bank filter
                    if (value2?.length > 0) {
                      newTableData = newTableData.filter((el) => el.bank_name === value2);
                    }

                    // status filter
                    if (value3?.length > 0) {
                      newTableData = newTableData.filter((el) => el.status === value3);
                    }

                    setFilteredData(newTableData.filter((el) => el.company_id === id));
                  }}
                  onClear={() => {
                    getDataClear();
                  }}
                  allowClear
                />
              </div>
              <Select
                style={{ width: 200 }}
                size="middle"
                label="Bank"
                value={value2}
                onChange={(e) => {
                  setValue2(e);
                  let newTableData = data;

                  // company filter
                  if (value1?.length > 0) {
                    newTableData = newTableData.filter((el) => el.company_id === value1);
                  }

                  // status filter
                  if (value3?.length > 0) {
                    newTableData = newTableData.filter((el) => el.status === value3);
                  }

                  if (typeof e === 'undefined') {
                    setFilteredData(newTableData);
                    return;
                  }

                  setFilteredData(newTableData.filter((el) => el.bank_name === e));
                }}
                allowClear
              >
                <Select.Option value="Unlimint">Unlimint</Select.Option>
                <Select.Option value="Ecomm">Ecomm</Select.Option>
              </Select>
              <Select
                style={{ width: 200 }}
                size="middle"
                label="Status"
                value={value3}
                onChange={(e) => {
                  setValue3(e);

                  let newTableData = data;

                  // company filter
                  if (value1?.length > 0) {
                    newTableData = newTableData.filter((el) => el.company_id === value1);
                  }

                  // bank filter
                  if (value2?.length > 0) {
                    newTableData = newTableData.filter((el) => el.bank_name === value2);
                  }

                  if (typeof e === 'undefined') {
                    setFilteredData(newTableData);
                    return;
                  }

                  setFilteredData(newTableData.filter((el) => el.status === e));
                }}
                allowClear={true}
              >
                <Select.Option value="active">
                  <Status status="ACTIVE" style={{ width: '100%' }} />
                </Select.Option>
                <Select.Option value="closed">
                  <Status status="CLOSED" style={{ width: '100%' }} />
                </Select.Option>
              </Select>
            </div>

            <Table
              columns={columns}
              dataSource={filteredData}
              initialHeight="500px"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setActiveRecord(record);
                    setIsOpenEditModal(true);
                  },
                };
              }}
            />
          </>
        )}
      </div>
      {isOpenAddModal && (
        <AddModal
          onClose={() => {
            setIsOpenAddModal(false);
          }}
          getData={getData}
        />
      )}
      {isOpenEditModal && (
        <EditModal
          onClose={() => {
            setIsOpenEditModal(false);
          }}
          getData={getData}
          activeRecord={activeRecord}
        />
      )}
    </>
  );
}
