const base = '/';
const auth = '/login';
const transaction = '/oldtransactions';
const transactions = '/transactions';
const viewTransactions = '/transactions/:id';
const transactionCreate = '/oldtransactions/create';
const transactionsCreate = '/transactions/create';
const adjustingTransaction = '/adjustingTransaction';
const adjustingTransactionCreate = '/adjustingTransaction/create';
const adjustingTransactionEdit = '/adjustingTransaction/:transactionId';
const conversionTransaction = '/conversion';
const conversionTransactionCreate = '/conversion/create';
const conversionTransactionEdit = '/conversion/:transactionId';
const transactionEdit = '/oldtransactions/:transactionId';
const activationOrder = '/activationOrder';
const activationOrderCreate = '/activationOrder/:status/:type';
const activationOrderCreateLink = '/activationOrder/create';
const activationOrderEdit = '/activationOrder/:activationOrderId';
const activationOrderEditForm = '/activationOrder/:status/:type/:activationOrderId';
const purchaseRequestList = '/purchase-request';
const interestsReport = '/interestsReport';
const company = '/company';
const companyCreate = '/company/create';
const companyEdit = '/company/edit/:companyId';
const companyById = '/company/:companyId';
const companyByIdSecond = '/company/:slug/:companyId';
const addNewPlatformForCompany = '/company/revenue-sources/:companyId/add_new_platform';
const users = '/users';
const user = '/users/:userId';
const createUser = '/users/create';
const createAdmin = '/users/create-admin';

const admin = '/admin';
const adminCreate = '/admin/create';
const adminEdit = '/admin/:adminId';
const transactionReport = '/transactionReport';
const transactionReportFlat = '/transactionReportFlat';
const transactionReportDetailed = '/transactionReportDetailed';
const expectedPayments = '/expectedPayments';
const monthlyReport = '/monthlyReport';
const transactionEntries = '/transactionEntries';
const monthlyPersonalReport = '/monthlyPersonalReport';
const systemSettings = '/systemSettings/:submenuPath';
const systemPath = '/system/:submenuPath';
const settingsPath = '/settings/:submenuPath';
const directoriesPath = '/directories/:submenuPath';
const system = '/system';
const settings = '/settings';
const settingsGeneral = '/settings/general';
const settingsRecalculate = '/settings/recalculate';
const settingsFactorDetails = '/settings/factor_details';
const settingsKeycloak = '/settings/keycloak';
const settingsGoogleApi = '/settings/google_api';
const settingsSubAccounts = '/settings/sub_accounts';
const directories = '/directories';
const activationOrderCreationData = '/activationOrderCreationData';
const activationOrderList = '/activationOrderList';
const checkInQueueList = '/signUpStatus';
const checkInQueueEdit = '/signUpStatus/:checkInQueueId';
const loadOldData = '/loadOldData';
const ImportData = '/ImportData';
const pivotTransactions = '/pivotTransactionReport';
const clientAmountsReport = '/clientAmountsReport';
const principalAmounts = '/principalAmountsReport';
const accrualReports = '/accrualReport';
const purchaseRequest = '/purchase-request/:purchaseRequestId';
const createPurchaseRequest = '/create/purchase-request';
const editPurchaseRequest = '/purchase-request/edit/:purchaseRequestId';
const reconciliationReport = '/reconciliationReport';
const sellerAccountBalance = '/sellerAccountBalance';
const revenueSourceList = '/revenue_source';
const revenueSourcesCreate = '/revenue_source/create';
const revenueSourcesJustEdit = '/revenue_source/edit';
const revenueSourcesEdit = '/revenue_source/edit/:platformId';
const revenueStore = '/revenue_store';
const transactionsFormCreate = '/transactions/:status/:type';
const transactionsFormEdit = '/transactions/:status/:type/:transaction_id';
const dashboard = '/dashboard';
const workingCapital = '/workingCapital';
const workingCapitalById = '/workingCapital/:workingCapitalId';
const workingCapitalCreate = '/workingCapital/create';
const workingCapitalEdit = '/workingCapital/edit/:workingCapitalId';
const workingCapitalPartialRepayment = '/workingCapital/partialRepayment/:workingCapitalId';
const repaymentSchedule = '/repaymentSchedule';
// No Access
const noAccess = '/no-access';

export default {
  getBase: () => base,
  getAuth: () => auth,
  getTransaction: () => transaction,
  getViewTransactions: () => viewTransactions,
  getTransactions: () => transactions,
  getTransactionCreate: () => transactionCreate,
  getTransactionsCreate: () => transactionsCreate,
  getTransactionsFormCreate: () => transactionsFormCreate,
  getTransactionsFormEdit: () => transactionsFormEdit,
  getTransactionEdit: () => transactionEdit,
  getAdjustingTransaction: () => adjustingTransaction,
  getAdjustingTransactionCreate: () => adjustingTransactionCreate,
  getAdjustingTransactionEdit: () => adjustingTransactionEdit,
  getConversionTransaction: () => conversionTransaction,
  getConversionTransactionCreate: () => conversionTransactionCreate,
  getConversionTransactionEdit: () => conversionTransactionEdit,
  getActivationOrder: () => activationOrder,
  getActivationOrderCreate: () => activationOrderCreate,
  getActivationOrderList: () => activationOrderList,
  getActivationOrderEdit: () => activationOrderEdit,
  getActivationOrderEditForm: () => activationOrderEditForm,
  getPurchaseRequestList: () => purchaseRequestList,
  getPurchaseRequest: () => purchaseRequest,
  getPurchaseRequestCreate: () => createPurchaseRequest,
  getPurchaseRequestEdit: () => editPurchaseRequest,
  getCommissionReport: () => interestsReport,
  getCompany: () => company,
  getCompanyCreate: () => companyCreate,
  getCompanyEdit: () => companyEdit,
  getCompanyById: () => companyById,
  getCompanyByIdSecond: () => companyByIdSecond,
  getUsers: () => users,
  getUser: () => user,
  getCreateUser: () => createUser,
  getCreateAdmin: () => createAdmin,
  getAdmin: () => admin,
  getAdminCreate: () => adminCreate,
  getAdminEdit: () => adminEdit,
  getTransactionReport: () => transactionReport,
  getTransactionReportFlat: () => transactionReportFlat,
  getTransactionReportDetailed: () => transactionReportDetailed,
  getExpectedPayments: () => expectedPayments,
  getMonthlyReport: () => monthlyReport,
  getTransactionEntries: () => transactionEntries,
  getMonthlyPersonalReport: () => monthlyPersonalReport,
  getSystemSettings: () => systemSettings,
  getSystem: () => system,
  getSettings: () => settings,
  getSettingsGeneral: () => settingsGeneral,
  getSettingsRecalculate: () => settingsRecalculate,
  getSettingsFactorDetails: () => settingsFactorDetails,
  getSettingsKeycloak: () => settingsKeycloak,
  getSettingsGoogleApi: () => settingsGoogleApi,
  getSettingsSubAccounts: () => settingsSubAccounts,
  getDirectories: () => directories,
  getSystemPath: () => systemPath,
  getSettingsPath: () => settingsPath,
  getDirectoriesPath: () => directoriesPath,
  getActivationOrderCreationData: () => activationOrderCreationData,
  getActivationOrderCreateLink: () => activationOrderCreateLink,
  getCheckInQueueList: () => checkInQueueList,
  getCheckInQueueEdit: () => checkInQueueEdit,
  getLoadOldData: () => loadOldData,
  getImportData: () => ImportData,
  getPivotTransactions: () => pivotTransactions,
  getClientAmountsReport: () => clientAmountsReport,
  getPrincipalAmounts: () => principalAmounts,
  getAccrualReport: () => accrualReports,
  getReconciliationReport: () => reconciliationReport,
  getSellerAccountBalance: () => sellerAccountBalance,
  getRevenueSourceList: () => revenueSourceList,
  getRevenueSourcesCreate: () => revenueSourcesCreate,
  getRevenueSourcesEdit: () => revenueSourcesEdit,
  getRevenueSourcesJustEdit: () => revenueSourcesJustEdit,
  getRevenueStore: () => revenueStore,
  getAddNewPlatformForCompany: () => addNewPlatformForCompany,
  getDashboard: () => dashboard,
  getWorkingCapital: () => workingCapital,
  getWorkingCapitalById: () => workingCapitalById,
  getWorkingCapitalCreate: () => workingCapitalCreate,
  getWorkingCapitalEdit: () => workingCapitalEdit,
  getWorkingCapitalPartialRepayment: () => workingCapitalPartialRepayment,
  getRepaymentSchedule: () => repaymentSchedule,
  // No Access
  getNoAccess: () => noAccess,
};
