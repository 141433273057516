import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, Input, Select, countries } from '@gamesb42/ui-kit';
import { Form } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';

import { CompanyKeyEnum } from 'types/CompanyTypes';
import { CURRENCY_OPTIONS } from 'constants/currency';

import { CompanyType } from '../../forms/CompanyForm/data';

import styles from './styles.module.scss';
import { COMPANY_FORM_OPTIONS } from 'constants/company';

const General: FC<{ isClientType: boolean; isFactor?: boolean }> = ({ isClientType, isFactor }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const typeValue = Form.useWatch('type', form);
  const [searchCountryText, setSearchCountryText] = useState('');

  const optionsType = useMemo(
    () => Object.values(CompanyType).map((value) => ({ label: t(`company.types.${value}`), value })),
    [t],
  );

  const prepareCountries =
    searchCountryText.length === 0 && !form.getFieldValue('country')
      ? []
      : (countries
          .map(({ Flag, value, label }) => {
            if (
              (!label.toLowerCase().includes(searchCountryText.toLowerCase()) && searchCountryText.length > 0) ||
              (form.getFieldValue('country') !== value && searchCountryText.length === 0)
            )
              return null;

            const startIndex = label.toLowerCase().indexOf(searchCountryText.toLowerCase());
            const endIndex = startIndex + searchCountryText.length;

            return {
              value,
              labelForSearch: label,
              label: (
                <div className={styles.block}>
                  <Flag size={16} />{' '}
                  <span>
                    {label.slice(0, startIndex)}
                    <span className={styles.boldCountry}>{label.slice(startIndex, endIndex)}</span>
                    {label.slice(endIndex)}
                  </span>
                </div>
              ),
            };
          })
          .filter((item) => item !== null) as DefaultOptionType[]);

  return (
    <>
      {!isFactor && (
        <FormLayout>
          <Form.Item name={CompanyKeyEnum.TYPE} rules={[{ required: true, message: 'Please select your type!' }]}>
            <Select label={t('company.type')} options={optionsType} size="large" />
          </Form.Item>
          <Form.Item
            name={CompanyKeyEnum.OPERATING_CURRENCY}
            rules={[
              {
                required: ['revenue-source', 'third-party'].includes(typeValue) ? false : true,
                message: 'Please select your operating currency!',
              },
            ]}
          >
            {!['revenue-source', 'third-party'].includes(typeValue) && (
              <Select label={t('company.operatingCurrency')} options={CURRENCY_OPTIONS} size="large" />
            )}
          </Form.Item>
        </FormLayout>
      )}
      {!isFactor && isClientType && (
        <Form.Item
          name={CompanyKeyEnum.COMPANY_FORM}
          rules={[{ required: true, message: 'Please select your company form!' }]}
        >
          <Select label={t('company.companyForm')} options={COMPANY_FORM_OPTIONS} size="large" />
        </Form.Item>
      )}
      <Form.Item name={CompanyKeyEnum.NAME} rules={[{ required: true, message: 'Please input your company name!' }]}>
        <Input label={t('contentHeader.name')} size="large" />
      </Form.Item>
      <Form.Item
        name={CompanyKeyEnum.LEGAL_NAME}
        rules={[{ required: true, message: 'Please input your legal name!' }]}
      >
        <Input label={t('contentHeader.legalName')} size="large" />
      </Form.Item>
      <FormLayout>
        <Form.Item
          name={CompanyKeyEnum.REGISTRATION_NUMBER}
          rules={[{ required: isClientType, message: 'Please select your registration number!' }]}
        >
          <Input label={t('systemSetting.registration')} size="large" />
        </Form.Item>
        <Form.Item
          name={CompanyKeyEnum.TAX_NUMBER}
          rules={[{ required: isClientType, message: 'Please select your tax number!' }]}
        >
          <Input label={t('systemSetting.tax_number')} size="large" />
        </Form.Item>
      </FormLayout>
      <Form.Item
        name={CompanyKeyEnum.WEBSITE}
        rules={[
          { required: !isClientType, message: 'Please input your website!' },
          { message: 'Please input correct website', type: 'url' },
        ]}
      >
        <Input label={t('systemSetting.website')} size="large" />
      </Form.Item>
      <Form.Item
        name={CompanyKeyEnum.EMAIL}
        rules={[
          { required: isClientType, message: 'Please input your email!' },
          { type: 'email', message: 'Please input correct email!' },
        ]}
      >
        <Input label={t('systemSetting.companyEmail')} size="large" />
      </Form.Item>
      <FormLayout>
        <Form.Item
          name={CompanyKeyEnum.PHONE}
          rules={[
            { required: isClientType, message: 'Please input your company number!' },
            // eslint-disable-next-line no-useless-escape
            { pattern: /^[\+]?[\d]{7,}?/, message: 'Please input phone number!' },
            { min: 8, message: 'Ensure this value has at least 8 character!' },
          ]}
        >
          <Input label={t('systemSetting.companyPhone')} size="large" />
        </Form.Item>
        <div />
      </FormLayout>
      <Form.Item name={CompanyKeyEnum.COUNTRY} rules={[{ required: true, message: 'Please select your country!' }]}>
        <Select
          label={t('global.country')}
          data-cy="company-country"
          size="large"
          onSearch={setSearchCountryText}
          showSearch
          filterOption
          optionFilterProp="labelForSearch"
          options={prepareCountries}
          notFoundContent={null}
          className={styles.selectCountry}
        />
      </Form.Item>
      <FormLayout>
        <Form.Item
          name={CompanyKeyEnum.ZIP}
          rules={[{ required: isClientType, message: 'Please input your zip or postal code!' }]}
        >
          <Input label={t('systemSetting.zip')} size="large" />
        </Form.Item>
        <Form.Item name={CompanyKeyEnum.STATE}>
          <Input label={t('systemSetting.state')} size="large" />
        </Form.Item>
      </FormLayout>
      <Form.Item name={CompanyKeyEnum.CITY} rules={[{ required: isClientType, message: 'Please input your city!' }]}>
        <Input label={t('systemSetting.city')} size="large" />
      </Form.Item>
      <Form.Item
        name={CompanyKeyEnum.ADDRESS}
        rules={[{ required: isClientType, message: 'Please input your address!' }]}
      >
        <Input label={t('systemSetting.address')} size="large" />
      </Form.Item>
      <Form.Item name="google_drive_url" rules={[{ message: 'Please input correct website', type: 'url' }]}>
        <Input label="Google Drive url" size="large" />
      </Form.Item>
    </>
  );
};

export default General;
