// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';

import { CompanyStatus } from 'types/CompanyTypes';

import classes from './styles.module.scss';

export enum StatusEnumTitle {
  ACTIVE = 'ACTIVE',
  REPAID = 'REPAID',
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NEW = 'NEW',
  FUNDED = 'FUNDED',
  SIGNED = 'SIGNED',
  YES = 'YES',
  NO = 'NO',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
  NOT_FUNDED = 'NOT FUNDED',
  // NOTE: Новые статусы взамен старых
  new = 'new',
  in_review = 'in-review',
  active = 'active',
  rejected = 'rejected',
  blocked = 'blocked',
  archive = 'archive',
  deleted = 'deleted',
  verified = 'verified',
  signing = 'signing',
  ready_for_approval = 'ready_for_approval',
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  inactive = 'inactive',
  closed = 'CLOSED',
  funded = 'funded',
  repaid = 'repaid',
}

export type CompanyStatus =
  | StatusEnumTitle.new
  | StatusEnumTitle.in_review
  | StatusEnumTitle.active
  | StatusEnumTitle.rejected
  | StatusEnumTitle.blocked
  | StatusEnumTitle.archive
  | StatusEnumTitle.deleted
  | StatusEnumTitle.verified
  | StatusEnumTitle.signing;

export type AOStatus =
  | StatusEnumTitle.repaid
  | StatusEnumTitle.funded
  | StatusEnumTitle.ready_for_approval
  | StatusEnumTitle.signing
  | StatusEnumTitle.active
  | StatusEnumTitle.rejected
  | StatusEnumTitle.inactive
  | StatusEnumTitle.deleted;

export const CompanyStatusArray = [
  StatusEnumTitle.new,
  StatusEnumTitle.in_review,
  StatusEnumTitle.active,
  StatusEnumTitle.rejected,
  StatusEnumTitle.blocked,
  StatusEnumTitle.archive,
  StatusEnumTitle.deleted,
  StatusEnumTitle.verified,
  StatusEnumTitle.signing,
  StatusEnumTitle.ready_for_approval,
];

export const AOStatusArray = [
  StatusEnumTitle.repaid,
  StatusEnumTitle.funded,
  StatusEnumTitle.ready_for_approval,
  StatusEnumTitle.signing,
  StatusEnumTitle.active,
  StatusEnumTitle.rejected,
  StatusEnumTitle.inactive,
  StatusEnumTitle.deleted,
];

export const CompanyStatusToName = {
  [StatusEnumTitle.new]: StatusEnumTitle.NEW,
  [StatusEnumTitle.in_review]: 'IN REVIEW',
  [StatusEnumTitle.active]: StatusEnumTitle.ACTIVE,
  [StatusEnumTitle.rejected]: StatusEnumTitle.REJECTED,
  [StatusEnumTitle.blocked]: StatusEnumTitle.BLOCKED,
  [StatusEnumTitle.archive]: 'ARCHIVE',
  [StatusEnumTitle.deleted]: StatusEnumTitle.DELETED,
  [StatusEnumTitle.verified]: 'VERIFIED',
  [StatusEnumTitle.signing]: 'SIGNING',
  [StatusEnumTitle.ready_for_approval]: 'READY FOR APPROVAL',
  [StatusEnumTitle.funded]: 'FUNDED',
  [StatusEnumTitle.repaid]: 'REPAID',
};

const getStyle = (status: StatusEnumTitle): CSSProperties => {
  switch (status) {
    case StatusEnumTitle.ACTIVE:
    case StatusEnumTitle.active:
    case StatusEnumTitle.SIGNED:
      return { backgroundColor: '#EAF8EB', color: '#26B634' };
    case StatusEnumTitle.PENDING:
    case StatusEnumTitle.archive:
      return { backgroundColor: '#E4E7E7', color: '#838998' };
    case StatusEnumTitle.blocked:
    case StatusEnumTitle.BLOCKED:
    case StatusEnumTitle.REJECTED:
    case StatusEnumTitle.rejected:
    case StatusEnumTitle.DELETED:
    case StatusEnumTitle.deleted:
    case StatusEnumTitle.closed:
      return { backgroundColor: '#FEECF1', color: '#EC3E72' };
    case StatusEnumTitle.NEW:
    case StatusEnumTitle.new:
      return { backgroundColor: '#F0EBFD', color: '#6335E4' };
    case StatusEnumTitle.FUNDED:
    case StatusEnumTitle.funded:
      return { backgroundColor: '#E3EDFE', color: '#6A7DED' };
    case StatusEnumTitle.YES:
      return { backgroundColor: 'transparent', color: '#26B634' };
    case StatusEnumTitle.NO:
      return { backgroundColor: 'transparent', color: '#EC3E72' };
    case StatusEnumTitle.INACTIVE:
    case StatusEnumTitle.in_review:
    case StatusEnumTitle.inactive:
      return { backgroundColor: '#FFF5F2', color: '#F57B51' };
    case StatusEnumTitle.NOT_FUNDED:
      return { backgroundColor: '#E4E7E7', color: '#838998', padding: '0px 8px', width: 110 };
    case StatusEnumTitle.signing:
    case StatusEnumTitle.SIGNING:
    case StatusEnumTitle.REPAID:
    case StatusEnumTitle.repaid:
      return { backgroundColor: '#E9F7FA', color: '#1EA7C6' };
    case StatusEnumTitle.verified:
    case StatusEnumTitle.VERIFIED:
    case StatusEnumTitle.ready_for_approval:
    case StatusEnumTitle.READY_FOR_APPROVAL:
      return { backgroundColor: '#E3EDFE', color: '#4A78ED' };
    default:
      return {};
  }
};

interface Props {
  status?: StatusEnumTitle | CompanyStatus;
  style?: CSSProperties;
  newColors?: CSSProperties;
  className?: string;
}

const Status: FC<Props> = ({ status, style, newColors, className }) => (
  <span className={classNames(classes.status, className)} style={{ ...style, ...getStyle(status), ...newColors }}>
    {CompanyStatusArray.includes(status) ? CompanyStatusToName[status] : status}
  </span>
);

export default Status;
