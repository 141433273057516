import { CSSProperties } from 'react';

import { AoTypeEnum } from 'hooks/api/useActivationOrder';

export const MAP_AO_TYPE_TO_COLOR: Record<AoTypeEnum, CSSProperties> = {
  [AoTypeEnum.CLASSIC]: { backgroundColor: '#95B3FF' },
  [AoTypeEnum.FUTURE_RECEIVABLES]: { backgroundColor: '#B550E0' },
  [AoTypeEnum.AVAILABLE_RECEIVABLES]: { backgroundColor: '#F57B51' },
  [AoTypeEnum.APPLAUD_FIX]: { backgroundColor: '#838998' },
};

export enum MAP_AO_TYPE_TO_STRING {
  classic = 'forms.activationOrder.title.classic',
  applaud_fix = 'forms.activationOrder.title.applaud',
  future_receivables = 'forms.activationOrder.title.futureReceivables',
  available_receivables = 'forms.activationOrder.title.availableReceivables',
}
