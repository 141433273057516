// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import endpoints from 'consts/endpoints';
import Modal from 'helpers/PopUpLayout';
import { getToken } from 'helpers/token';
import { convertFileToBase64 } from 'helpers/file';
import { formatDateIsoShort } from 'helpers/formatters';
import { PurchaseRequestSetStatusT } from 'queries/purchaseRequest/setNewStatus';
import { PurchaseRequestDataType, PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';
import { UseStylingButton, UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import DateInput from 'components/inputs/DateInput';
import Textarea from 'components/uiKit/TextareaKit';
import { AmountKit } from 'components/uiKit/AmountKit';
import DropZoneDocument from 'components/forms/CompanyForm/DropZoneDocument';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import CheckboxKit from 'components/uiKit/CheckboxKit';

import { MAP_TYPE_TO_INITIAL_VALUE, MAP_TYPE_TO_BUTTON_SUBMIT_TEXT, TypeAllValues } from './data';
import styles from './styles.module.scss';
import { FormLayout } from '@gamesb42/ui-kit';
import FactorAccountsSelect from 'components/FactorAccountsSelect';

type PropsT = {
  type: PurchaseRequestStatus;
  isOpen: boolean;
  onClose: () => void;
  purchaseRequestData: PurchaseRequestDataType;
  setStatus: (params: PurchaseRequestSetStatusT['params']) => Promise<void>;
};

const PurchaseStatusModal: FC<PropsT> = (props) => {
  const { type, isOpen, onClose, purchaseRequestData, setStatus } = props;
  const { id, purchase_price, currency } = purchaseRequestData;
  const initialValues = MAP_TYPE_TO_INITIAL_VALUE[type] || {};
  const [values, setValues] = useState<TypeAllValues>(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const stylingButton = UseStylingButton();
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const [file, setFile] = useState<File[]>([]);
  const {
    companiesData: { names = [] },
    getCompanies,
  }: useCompanyType = useCompany();

  console.log('names', names);

  const onChange = (key: string, value: string | boolean | object) => {
    setValues((prevValues) => ({ ...prevValues, [key]: value }));
  };

  const handleDropZoneChange = async (access: File[]) => {
    if (access.length === 0) {
      onChange('file', '');
      setFile(access);
    }

    if (access.length > 0) {
      const fileUrl: string = (await convertFileToBase64(access[0])) as string;
      setValues((prevValues) => ({ ...prevValues, file_data: fileUrl }));
      setFile(access);
    }
  };

  const setFilePR = async (body) => {
    try {
      const res = await fetch(endpoints.getCreateSignedPurchaseDocumentUrl(id), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(body),
      });
      const statusCode = await res;

      if (!statusCode.ok) {
        return (await res.json()) as Promise<any>;
      }

      return (await res.json()) as Promise<any>;
    } catch (iserror) {
      return iserror as any;
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (type === PurchaseRequestStatus.SIGNED) {
      const result = await setFilePR(values);
      await setStatus({ file_id: result.file_id, id, status: type });
    }

    if (type !== PurchaseRequestStatus.SIGNED) {
      if (values.checkbox) {
        delete values.recipient;
      }

      if (!values.checkbox) {
        values.recipient = { company_id: values.recipient };
      }
      delete values.checkbox;

      await setStatus({ ...values, id, status: type });
      setValues(initialValues);
    }
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    if (type === PurchaseRequestStatus.FUNDED) {
      setValues({
        ...MAP_TYPE_TO_INITIAL_VALUE[type],
        tx_date: formatDateIsoShort(new Date()),
        currency: currency || 'EUR',
        amount: purchase_price,
      });
    }

    if (type !== PurchaseRequestStatus.FUNDED) {
      setValues(initialValues);
    }
  }, [type]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <div className={styles.paper}>
          {type === PurchaseRequestStatus.REJECTED && (
            <div className={styles.wrapperRejected}>
              <Textarea
                value={values?.comment || ''}
                onChange={(event) => onChange('comment', event.target.value)}
                maxLength={2000}
                placeholder={t('forms.purchaseRequest.changeStatusModal.rejected.comment')}
              />
            </div>
          )}
          {type === PurchaseRequestStatus.SIGNED && (
            <div className={styles.wrapperSigned}>
              <DropZoneDocument
                additionalClassNameForContent={styles.dropZoneContent}
                title={t('forms.purchaseRequest.changeStatusModal.signed.titleFile')}
                text={t('forms.purchaseRequest.changeStatusModal.signed.additionalText')}
                buttonText={t('forms.purchaseRequest.changeStatusModal.signed.titleButton')}
                setAccessFiles={handleDropZoneChange}
                accessFiles={file}
              />
            </div>
          )}
          {type === PurchaseRequestStatus.FUNDED && (
            <div className={styles.transactionWrapper}>
              <p className={styles.transactionTitle}>{t('forms.purchaseRequest.changeStatusModal.funded.title')}</p>
              <FormLayout>
                <DateInput
                  commonStyle={{ height: 60, maxWidth: 290 }}
                  isNotErrorLocal
                  onChangeDateHandler={({ target: { value } }) => onChange('tx_date', value)}
                  date={values?.tx_date || ''}
                  labelKey={t('forms.purchaseRequest.changeStatusModal.funded.date')}
                  required
                />
                <AmountKit
                  isNeedHandlePaste
                  label={t('forms.purchaseRequest.changeStatusModal.funded.amount')}
                  onChange={(event) => onChange('amount', event.target.value)}
                  amount={values?.amount || ''}
                  currency={values?.currency || undefined}
                  required
                  wrapProps={{ height: 60 }}
                />
              </FormLayout>
              <FactorAccountsSelect
                needSetDefaultValue
                currency={values?.currency}
                value={values?.sender?.account}
                onChange={(account, { source: { bank_name, swift } }) =>
                  onChange('sender', {
                    currency: values?.currency,
                    account,
                    bank_name,
                    swift,
                  })
                }
                required
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              />
              {/* <div className={styles.transactionFields}>
                <SelectKit
                  label={t('forms.purchaseRequest.changeStatusModal.funded.currency')}
                  options={getCurrencyList()}
                  keyObj="name"
                  val={getValueCurrency()[values?.currency] || ''}
                  changeSelectItem={(_, data) => {
                    onChange('currency', data.key);
                  }}
                  required
                />
              </div> */}
              <CheckboxKit
                label={t('forms.transaction.recipientIsTheSameAsClient')}
                checked={values.checkbox}
                onClick={() => {
                  names.length === 0 && getCompanies({ showDeleted: false });
                  onChange('checkbox', !values.checkbox);
                }}
              />
              {!values.checkbox && (
                <DropdownKit
                  label={t('forms.transaction.recipient')}
                  options={names}
                  keyObj="name"
                  value={names.find((item) => item.id === values.recipient) || ''}
                  changeSelectItem={(_, data) => {
                    onChange('recipient', data.id);
                  }}
                  required
                  positionType={DropdownSizeEnum.SIZE_60}
                />
              )}
            </div>
          )}
          {type === PurchaseRequestStatus.REPAID && (
            <div>
              <DateInput
                commonStyle={{ height: 60, width: '100%' }}
                isNotErrorLocal
                onChangeDateHandler={({ target: { value } }) => onChange('repayment_date', value)}
                date={values?.repayment_date || ''}
                labelKey={t('forms.purchaseRequest.changeStatusModal.repaid.date')}
                required
              />
            </div>
          )}
          <div className={styles.actionWrapper}>
            <Button
              disabled={
                values && !isLoading
                  ? Object.entries(values)
                      .filter((item) => {
                        const key = item[0];

                        if (key === 'checkbox' || (key === 'recipient' && values.checkbox)) {
                          return false;
                        }

                        return true;
                      })
                      .map((item) => item[1])
                      .some((value) => !value)
                  : true
              }
              type="submit"
              style={{ padding: '17px 19px', lineHeight: '24px', fontSize: 16 }}
              {...stylingButton}
            >
              {t(MAP_TYPE_TO_BUTTON_SUBMIT_TEXT[type])}
            </Button>
            <Button
              type="button"
              onClick={onClose}
              style={{ padding: '17px 19px', lineHeight: '24px', fontSize: 16 }}
              {...dropZoneStylingButton}
            >
              {t('forms.purchaseRequest.changeStatusModal.cancel')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default PurchaseStatusModal;
