//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Table, DatePicker, EurIcon, UsdIcon, Select, RangePicker, DatePickerProps, Input } from '@gamesb42/ui-kit';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { formatAmount } from 'helpers/formatters';
import ContentWrapper from 'components/ContentWrapper';
import { getToken } from 'helpers/token';
import { CURRENCY_OPTIONS } from 'constants/currency';
import CompanySelect from 'components/CompanySelect';

import s from './repaymentSchedule.module.scss';

interface QueryParams {
  sortedBy?: string;
}

export default function RepaymentSchedule() {
  const [repaymentLoading, setRepaymentLoading] = useState(true);
  const [repaymentData, setRepaymentData] = useState([]);
  const [total, setTotal] = useState({ eur: '', usd: '' });
  const history = useHistory();
  const query: QueryParams = queryString.parse(history.location.search, { parseNumbers: true });
  const [currency, setCurrency] = useState(query?.currency);
  const [period, setPeriod] = useState<DatePickerProps['value']>(undefined);
  const [productType, setProductType] = useState();
  const [sender, setSender] = useState();
  const [clientId, setClientId] = useState();

  const repaymentColumns = [
    {
      title: 'Sender',
      dataIndex: 'sender',
      minWidth: '116px',
      align: 'left' as 'left',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      minWidth: '116px',
      align: 'left' as 'left',
    },
    {
      title: <div className={s.header}>Product type</div>,
      dataIndex: 'type_of_product',
      minWidth: '120px',
      align: 'left' as 'left',
    },
    {
      title: <div className={s.header}>Expected date'</div>,
      minWidth: '128px',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return moment(record.expected_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
    },
    {
      title: <div className={s.header}>Payment type</div>,
      dataIndex: 'type_of_payment',
      minWidth: '124px',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return record.type_of_payment === 'Penalty' ? 'DI' : record.type_of_payment;
      },
    },
    {
      title: 'EUR',
      width: '116px',
      align: 'right' as 'right',
      render: (_: any, record: any) => {
        return formatAmount(record.amount_eur);
      },
    },
    {
      title: 'USD',
      width: '116px',
      align: 'right' as 'right',
      render: (_: any, record: any) => {
        return formatAmount(record.amount_usd);
      },
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: '100px',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        console.log({ record });
        return (
          <div className={s.link} onClick={() => history.push(`${record.url}`)}>
            {record.details}
          </div>
        );
      },
    },
  ];

  function getRepaymentSchedule() {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('authorization', `Bearer ${getToken()}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    let params = {};

    if (currency) {
      params.currency = currency.toLowerCase();
    }

    if (period) {
      params.from_date = period[0].format('YYYY-MM-DD');
      params.to_date = period[1].format('YYYY-MM-DD');
    }

    if (productType) {
      params.product_type = productType;
    }

    if (sender) {
      params.sender = sender;
    }

    if (clientId) {
      params.company_id = clientId;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/reports/repayment_schedule?${queryString.stringify(params)}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        setRepaymentData(result.items);
        setTotal({
          eur: result.items.reduce((acc, curr) => acc + (curr.amount_eur || 0), 0),
          usd: result.items.reduce((acc, curr) => acc + (curr.amount_usd || 0), 0),
        });
      })
      .catch(() => {})
      .finally(() => {
        setRepaymentLoading(false);
      });
  }

  useEffect(() => {
    getRepaymentSchedule();
  }, [currency, period, productType, sender, clientId]);

  return (
    <ContentWrapper style={{ padding: '20px 0 0 0', position: 'relative' }} maxWidthContent fullHeight>
      <div className={s.contentWrapper}>
        <div className={s.center}>
          <div className={s.title}>Repayment Schedule</div>

          <div className={s.filters}>
            <Select
              value={currency}
              style={{ width: '112px' }}
              label={'Currency'}
              options={CURRENCY_OPTIONS}
              onChange={(e) => setCurrency(e)}
              allowClear
            />
            <RangePicker size="medium" style={{ width: '260px' }} value={period} onChange={setPeriod} />
            <Input
              value={sender}
              onChange={(e) => setSender(e.target.value)}
              style={{ width: '266px' }}
              label="Sender"
              placeholder="Sender"
            />
            <div>
              <CompanySelect
                label={'Client'}
                size="middle"
                width="266px"
                value={clientId || undefined}
                onChange={(id) => setClientId(id)}
                allowClear
              />
            </div>
            <Select
              value={productType}
              style={{ width: '260px' }}
              label={'Product type'}
              options={[
                { label: 'MAX', value: 'MAX' },
                { label: 'NOW', value: 'NOW' },
                { label: 'Future', value: 'Future' },
              ]}
              onChange={(e) => setProductType(e)}
              allowClear
            />
          </div>

          <Table
            loading={repaymentLoading}
            initialHeight="500px"
            className={s.table}
            columns={repaymentColumns}
            dataSource={repaymentData}
            footer={() => (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0 100px 0 0' }}>
                <div style={{ paddingLeft: '16px' }}>
                  <b>Total</b>
                </div>

                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '120px', paddingRight: '12px' }}>
                    <b>{formatAmount(total.eur)}</b>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '120px', paddingRight: '15px' }}>
                    <b>{formatAmount(total.usd)}</b>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </ContentWrapper>
  );
}
