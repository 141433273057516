/* eslint-disable max-lines */
import i18next from 'i18next';

import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import { CurrencyType } from 'components/uiKit/InputKit';
import { PlatformDataType } from 'hooks/api/usePlatform';
import { typeInputEnum } from 'components/forms/ActivationOrderForm/CardsAo/SwitchCustomSettings';
import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';

export const statusAo = ['Active', 'Repaid'];

export const fileAssociation = ['application/pdf', 'image/jpeg', 'image/png'];

export enum statusParamsEnum {
  EDIT = 'edit',
  CREATE = 'create',
}

export enum KeyCardsEnum {
  EMPTY = 'empty',
  PURCHASED_RECEIVABLES = 'purchased_receivables',
  CHECKBOX = 'checkbox',
  RECIPIENT = 'recipient',
  COMPANY_ID = 'company_id',
  AO_TYPE = 'ao_type',
  CONTRACT_ID = 'contract_id',
  NAME = 'name',
  VAT_POLICY = 'vat_policy',
  PENALTY_POLICY = 'penalty_policy',
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  INTEREST = 'interest',
  REPAYMENT_DATE = 'repayment_date',
  PURCHASE_DATE = 'purchase_date',
  PAYMENT_DAYS = 'payment_days',
  GRACE_PERIOD = 'grace_period',
  PAYMENT_DAY = 'payment_day',
  APPLY_FROM_DATE = 'apply_from_date',
  COMMISSION_DISABLE_DATE = 'commission_disable_date',
  CAPITALIZATION_DISABLE_DATE = 'capitalization_disable_date',
  CAPITALIZATION_PERIOD_DISABLED = 'capitalization_period_disabled',
  DATE_START = 'date_start',
  DATE_END = 'date_end',
  DEFAULT_INTEREST_DISABLE_DATE = 'default_interest_disable_date',
  DAYS = 'days',
  STATUS = 'status',
  SIGN_DATE = 'sign_date',
  DISTRIBUTION_PLATFORMS = 'distribution_platforms',
  PLATFORM_ID = 'platform_id',
  PURCHASE_PERCENTAGE = 'purchase_percentage',
  COMMISSION = 'commission',
  PURCHASE_PRICE_PERCENTAGE = 'purchase_price_percentage',
  FUNDING_LIMIT = 'funding_limit',
  ACTIVATION_DATE = 'activation_date',
  REGISTRATION_NUMBER = 'registration_number',
  REPAYMENT_PLAN = 'repayment_plan',
  REPAYMENT_PLAN_DURATION = 'repayment_plan_duration',
  AO_DOCUMENT = 'ao_document',
  AO_DOCUMENT_ID = 'ao_document_id',
  COMMENT = 'comment',
  PAYMENT_PLAN = 'schedules',
  PAYMENT_DATE = 'payment_date',
  PAYMENT_AMOUNT = 'amount',
  PAYMENT_ID = 'id',
  PAYMENT_DELETED = 'deleted',
  PAYMENT_ORDER_ID = 'order_id',
  IS_CUSTOM_PAYMENT_DAY = 'is_custom_payment_day',
  WITHHOLD_PERCENTAGE = 'withhold_percentage',
  TX_DATE = 'tx_date',
  FACTOR_BANK_ACCOUNT = 'factor_bank_account',
  MFA_USD = 'mfa_usd',
  MFA_EUR = 'mfa_eur',

  INCLUDED = 'included',
  NOT_INCLUDED = 'not_included',
  NOT_VAT = 'no_vat',
  CLOSING_DATE = 'closing_date',

  PENALTY_15_PER_YEAR = 'penalty_15_per_year',
  PENALTY_40_PER_YEAR = 'penalty_40_per_year',
  PENALTY_011_PER_YEAR = 'penalty_011_per_day',

  EUR = 'EUR',
  USD = 'USD',

  CUSTOM_SETTINGS = 'custom_settings',
  DROPZONE = 'dropzone',
  ROOT = 'root',

  AO_DATE = 'ao_date',
  PURPOSE = 'purpose',
  MOCK_PURCHASED_RECEIVABLES = 'mockPurchaseReceivables',
  PERIOD = 'period',
  REVERSE_AMOUNT = 'reverse_amount',
  TRANSACTION_SECURITY = 'transaction_security',
  AO_NUMBER = 'ao_number',
}

export const switchListInitial = {
  [KeyCardsEnum.CUSTOM_SETTINGS]: false,
  [KeyCardsEnum.PAYMENT_DAYS]: false,
  [KeyCardsEnum.GRACE_PERIOD]: false,
  [KeyCardsEnum.COMMISSION_DISABLE_DATE]: false,
  [KeyCardsEnum.CAPITALIZATION_DISABLE_DATE]: false,
  [KeyCardsEnum.CAPITALIZATION_PERIOD_DISABLED]: false,
  [KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE]: false,
};

export const typeAo = [
  { name: 'FUTURE', key: AoTypeEnum.FUTURE_RECEIVABLES },
  { name: 'MAX', key: AoTypeEnum.CLASSIC },
  { name: 'NOW', key: AoTypeEnum.AVAILABLE_RECEIVABLES },
  { name: 'Applaud WP', key: AoTypeEnum.APPLAUD_FIX },
];

export const newTypeAo = typeAo.filter((item) => item.key !== AoTypeEnum.APPLAUD_FIX);

export const valueType = {
  [AoTypeEnum.FUTURE_RECEIVABLES]: typeAo[0],
  [AoTypeEnum.CLASSIC]: typeAo[1],
  [AoTypeEnum.AVAILABLE_RECEIVABLES]: typeAo[2],
  [AoTypeEnum.APPLAUD_FIX]: typeAo[3],
};

export const getVatPolicyList = () => [
  { key: KeyCardsEnum.INCLUDED, name: i18next.t('selectFiledValues.included') },
  { key: KeyCardsEnum.NOT_INCLUDED, name: i18next.t('selectFiledValues.not_included') },
  { key: KeyCardsEnum.NOT_VAT, name: i18next.t('selectFiledValues.no_vat') },
];

export const getValuePolicy = () => ({
  [KeyCardsEnum.INCLUDED]: getVatPolicyList()[0],
  [KeyCardsEnum.NOT_INCLUDED]: getVatPolicyList()[1],
  [KeyCardsEnum.NOT_VAT]: getVatPolicyList()[2],
});

export const getPenaltyList = () => [
  { key: KeyCardsEnum.PENALTY_15_PER_YEAR, name: i18next.t('selectFiledValues.penalty_15_per_year') },
  { key: KeyCardsEnum.PENALTY_40_PER_YEAR, name: i18next.t('selectFiledValues.penalty_40_per_year') },
  { key: KeyCardsEnum.PENALTY_011_PER_YEAR, name: i18next.t('selectFiledValues.penalty_011_per_day') },
];

export const getValuePenalty = () => ({
  [KeyCardsEnum.PENALTY_15_PER_YEAR]: getPenaltyList()[0],
  [KeyCardsEnum.PENALTY_40_PER_YEAR]: getPenaltyList()[1],
  [KeyCardsEnum.PENALTY_011_PER_YEAR]: getPenaltyList()[2],
});

export const getCurrencyList = () => [
  { key: KeyCardsEnum.EUR, name: i18next.t('selectFiledValues.eur') },
  { key: KeyCardsEnum.USD, name: i18next.t('selectFiledValues.usd') },
];

export const getValueCurrency = () => ({
  [KeyCardsEnum.EUR]: getCurrencyList()[0],
  [KeyCardsEnum.USD]: getCurrencyList()[1],
});

export const initialPaymentsDays = { [KeyCardsEnum.PAYMENT_DAY]: '', [KeyCardsEnum.APPLY_FROM_DATE]: '' };

export const getValuePaymentDays = (data: paymentDaysType[]) =>
  data.map(({ payment_day, apply_from_date }) => ({
    row: [
      {
        key: KeyCardsEnum.PAYMENT_DAY,
        label: i18next.t('company.customPaymentDay'),
        val: payment_day,
        type: typeInputEnum.TEXT,
      },
      { key: KeyCardsEnum.APPLY_FROM_DATE, label: i18next.t('tables.apply_from_date'), val: apply_from_date },
    ],
  }));

export const initialPeriodDisable = { [KeyCardsEnum.DATE_START]: '', [KeyCardsEnum.DATE_END]: '' };
export const choices_grace = Array.from(Array(10).keys()).map((i) => String(i + 1));

export const getValuePeriodDisable = (data: PeriodDisabledType[]) =>
  data.map(({ date_start, date_end }) => ({
    row: [
      {
        key: KeyCardsEnum.DATE_START,
        label: i18next.t('forms.activationOrder.capitalizationDisableDate'),
        val: date_start,
      },
      {
        key: KeyCardsEnum.DATE_END,
        label: i18next.t('forms.activationOrder.TurnOffCapitalizationTo'),
        val: date_end,
      },
    ],
  }));

export interface PaymentPlanT {
  [KeyCardsEnum.PAYMENT_AMOUNT]?: string;
  [KeyCardsEnum.PAYMENT_DATE]?: string;
  [KeyCardsEnum.PAYMENT_DELETED]?: boolean;
  [KeyCardsEnum.PAYMENT_ID]?: string;
  [KeyCardsEnum.PAYMENT_ORDER_ID]?: string;
}

export const getValuePaymentPlan = (data: PaymentPlanT[]) => {
  return data.map(({ payment_date, amount, id }) => ({
    isShowButtonDelete: id,
    row: [
      {
        key: KeyCardsEnum.PAYMENT_DATE,
        label: i18next.t('forms.activationOrder.paymentDate'),
        val: payment_date,
      },
      {
        key: KeyCardsEnum.PAYMENT_AMOUNT,
        label: i18next.t('forms.activationOrder.amount'),
        val: amount,
        type: typeInputEnum.TEXT,
      },
    ],
  }));
};

export interface PeriodDisabledType {
  [KeyCardsEnum.DATE_START]: '';
  [KeyCardsEnum.DATE_END]: '';
}

export interface paymentDaysType {
  [KeyCardsEnum.PAYMENT_DAY]: string;
  [KeyCardsEnum.APPLY_FROM_DATE]: string;
  editable?: boolean;
}

export enum StatusEnumAo {
  ACTIVE = 'ACTIVE',
  REPAID = 'REPAID',
  NEW = 'NEW',
  FUNDED = 'FUNDED',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}

export const listStatusAo = [
  { name: 'New', key: StatusEnumAo.NEW },
  { name: 'Funded', key: StatusEnumAo.FUNDED },
  { name: 'Blocked', key: StatusEnumAo.BLOCKED },
  { name: 'Deleted', key: StatusEnumAo.DELETED },
  { name: 'Active', key: StatusEnumAo.ACTIVE },
  { name: 'Repaid', key: StatusEnumAo.REPAID },
];

export const getValueStatus = {
  [StatusEnumAo.NEW]: listStatusAo[0],
  [StatusEnumAo.FUNDED]: listStatusAo[1],
  [StatusEnumAo.BLOCKED]: listStatusAo[2],
  [StatusEnumAo.DELETED]: listStatusAo[3],
  [StatusEnumAo.ACTIVE]: listStatusAo[4],
  [StatusEnumAo.REPAID]: listStatusAo[5],
};

export const initialDistributionPlatform = {
  [KeyCardsEnum.PLATFORM_ID]: '',
  [KeyCardsEnum.PURCHASE_PERCENTAGE]: '',
  [KeyCardsEnum.PURCHASE_PRICE_PERCENTAGE]: '',
  [KeyCardsEnum.FUNDING_LIMIT]: null,
  [KeyCardsEnum.CURRENCY]: '' as CurrencyType,
  [KeyCardsEnum.ACTIVATION_DATE]: '',
};

export type distributionPlatformsType = typeof initialDistributionPlatform;

export const getListDistributionPlatform = (list: PlatformDataType[]) => list.map((item) => item);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getValueDistributionPlatform = (id, list: PlatformDataType[]) => list.find((item) => item.id === id);

export enum RepaymentPlanEnumAo {
  EQUEAL_PARTS_EVERY_MONTH = 'equal_parts_every_month',
}
export const listRepaymentPlan = [
  { name: 'Equal parts every month', key: RepaymentPlanEnumAo.EQUEAL_PARTS_EVERY_MONTH },
];
export const getValueRepaymentPlan = {
  [RepaymentPlanEnumAo.EQUEAL_PARTS_EVERY_MONTH]: listRepaymentPlan[0],
};

export const initialOrderCardsData = {
  [KeyCardsEnum.COMPANY_ID]: '',
  [KeyCardsEnum.NAME]: '',
  [KeyCardsEnum.AMOUNT]: '',
  [KeyCardsEnum.CURRENCY]: '' as CurrencyType,
  [KeyCardsEnum.INTEREST]: '',
  [KeyCardsEnum.PURCHASE_DATE]: '',
  [KeyCardsEnum.REPAYMENT_DATE]: '',
  [KeyCardsEnum.GRACE_PERIOD]: '',
  [KeyCardsEnum.PAYMENT_DAYS]: [] as paymentDaysType[],
  [KeyCardsEnum.COMMISSION_DISABLE_DATE]: '',
  [KeyCardsEnum.CAPITALIZATION_DISABLE_DATE]: '',
  [KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE]: '',
  [KeyCardsEnum.CONTRACT_ID]: '',
  [KeyCardsEnum.VAT_POLICY]: '',
  [KeyCardsEnum.AO_TYPE]: '' as AoTypeEnum,
  [KeyCardsEnum.PENALTY_POLICY]: KeyCardsEnum.PENALTY_011_PER_YEAR,
  [KeyCardsEnum.REGISTRATION_NUMBER]: '',
  [KeyCardsEnum.CAPITALIZATION_PERIOD_DISABLED]: [] as PeriodDisabledType[],
  [KeyCardsEnum.DAYS]: '',
  [KeyCardsEnum.STATUS]: StatusEnumAo.NEW,
  [KeyCardsEnum.SIGN_DATE]: '',
  [KeyCardsEnum.DISTRIBUTION_PLATFORMS]: [{ ...initialDistributionPlatform }],
  [KeyCardsEnum.REPAYMENT_PLAN]: '',
  [KeyCardsEnum.REPAYMENT_PLAN_DURATION]: '',
  [KeyCardsEnum.DROPZONE]: [] as File[],
  [KeyCardsEnum.COMMENT]: '',
  [KeyCardsEnum.PAYMENT_PLAN]: [{ amount: '', payment_date: '' }] as PaymentPlanT[],
  [KeyCardsEnum.CLOSING_DATE]: '',
  [KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY]: false,
  [KeyCardsEnum.WITHHOLD_PERCENTAGE]: '',
  [KeyCardsEnum.PURCHASED_RECEIVABLES]: '',
  [KeyCardsEnum.TX_DATE]: '',
  [KeyCardsEnum.RECIPIENT]: '',
  [KeyCardsEnum.CHECKBOX]: true,
  [KeyCardsEnum.MFA_USD]: '',
  [KeyCardsEnum.MFA_EUR]: '',
  [KeyCardsEnum.PURPOSE]: '',
  [KeyCardsEnum.MOCK_PURCHASED_RECEIVABLES]: 100,
  [KeyCardsEnum.REVERSE_AMOUNT]: 'N/A',
  [KeyCardsEnum.TRANSACTION_SECURITY]: 'N/A',
  [KeyCardsEnum.AO_DATE]: '',
  [KeyCardsEnum.AO_NUMBER]: 0,
  [KeyCardsEnum.PERIOD]: '',
};

export const MAP_TYPE_AO_TO_TYPE_FILE = {
  [AoTypeEnum.CLASSIC]: 'activation_order_classic',
  [AoTypeEnum.APPLAUD_FIX]: 'activation_order_applaud',
  [AoTypeEnum.AVAILABLE_RECEIVABLES]: 'activation_order_available_receivables',
  [AoTypeEnum.FUTURE_RECEIVABLES]: 'activation_order_future_receivables',
};

export interface BodyRequestAoNumber {
  company_id: string;
  document_id: string;
  ao_number?: string;
}

export interface ResponceAoNumber {
  next_ao_number?: string;
  is_valid?: string;
  description?: { ao_number: string };
  title?: string;
}

export const getAoNumber = async (params?: BodyRequestAoNumber, check?: boolean) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await fetch(endpoints.getNextAONumber(params, check), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<ResponceAoNumber>;
  } catch (error) {
    return error as ResponceAoNumber;
  }
};
