import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import urls from 'constants/urls';

import useAlert from './api/useAlert';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useErrorCallback = (userCallback?: (text) => boolean | void) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const getAlert = useCallback((type: string, message: string) => setAlert({ type, message }), [setAlert]);
  const errorCallback = useCallback(
    ({ unauthorized, text, customParams = {} }) => {
      if (unauthorized) {
        history.push(urls.getAuth());
      } else if (
        text &&
        /^[\],:{}\s]*$/.test(
          text
            .replace(/\\["\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
        )
      ) {
        try {
          const errorsCallback = JSON.parse(text)?.description;
          const errorField = Object.keys(errorsCallback)[0];
          const errorCallbackText = errorsCallback[errorField];

          if (userCallback && !!userCallback({ errorField, errorCallbackText, customParams })) {
            getAlert('error', text);
          }
        } catch(e) {}
      } else {
        getAlert('error', text || 'Unknown Error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, setAlert],
  );

  return {
    errorCallback,
    getAlert,
  };
};

export default useErrorCallback;
